import React, { Component } from "react";
import Web3 from 'web3';
import toastr from 'toastr';
import $, { type } from 'jquery';
import ReactTooltip from 'react-tooltip';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Modal from '../modal';
import 'toastr/build/toastr.min.css';
import {
    LIST_ADDRESS_BLDR_MARKET_MAKER_MAIN, LIST_ADDRESS_NRGY_MAIN,
    LIST_ADDRESS_REFERRAL_MAIN, LIST_ADDRESS_USDC_MAIN, LIST_ADDRESS_USDC_NRGY_PAIR_MAIN,
    LIST_ADDRESS_WETH_NEW_MAIN, LIST_ADDRESS_ROUTE_NEW_MAIN, LIST_ABI_BLDR_MARKET_MAKER,
    LIST_ABI_BLDR,
    LIST_ABI_ERC20, LIST_ABI_USDC_NRGY_PAIR, LIST_ABI_REFERRAL,
    LIST_ABI_ROUTER, LIST_ADDRESS_BLDR_MAIN, LIST_ADDRESS_USDC_ETH, LIST_ADDRESS_BRIDGE_ETH,
    LIST_ABI_BRIDGE, MAINNET_HTTP_ETH, POLYGON_RPC,
    CHAIN_ID, BACKEND_SERVICE_URL
} from '../../config';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';

let decimal18 = 1000000000000000000;
let decimal6 = 1000000;

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

let refererDefault = '0x5637b42AbbCd602Cf1b994a1692D26a5B4A88d4d';
let userReferer = '';

export default class Invest extends Component {


    web3_BLDR_MARKET_MAKER = null;
    web3_BLDR = null;
    web3_NRGY = null;
    web3_USDC = null;
    web3_USDC_NRGY_PAIR_MAIN = null;
    web3_REFERRAL = null;
    web3_ROUTER = null;
    web3_WETH = null;
    web3_USDC_ETH = null;
    web3_BRIDGE_ETH = null;
    web3InfuraETH = null;
    web3_EthUSDCInstance = null;

    constructor(props) {
        super(props);
        this.state = {
            link: '',
            isActiveUser: false,
            isEnabled: false,
            isConnecting: false,
            popupVisible: false,
            totalShares: '-',
            totalRewards: '-',
            weekCount: '-',
            totalSharesThisWeek: '-',
            remainStakingLimitThisWeek: '-',
            lastRewardTime: '-',
            stakBldr: '-',
            mmStakingPoolShare: '-',
            rewardsEarnedThisWeek: '-',
            totalBldrEarned: '-',
            totalBldrEarnedUnformatted: '-',
            claimRestakeAmount: '',
            rewardStatus: 'start',
            rewardStatusType: null,
            unstakbldrAvail: '-',
            currentAssetId: 0,
            web3: null,
            account: '',
            usdcBal: "-",
            nrgyBal: "-",
            bldrBal: '-',
            wethBal: '-',
            stakeAmount: '',
            stakeAmountBLDR: '',
            stakeAmountETH: '',
            stakeAmountUSDC: '',
            stakeAmountNRGY: '',
            unstakeAmount: '',
            isStakeError: false,
            isWEthStakeError: false,
            isUsdcStakeError: false,
            isUnStakeError: false,
            stakeStatus: 'start',
            stakeStatusType: null,
            unStakeStatus: 'start',
            networkType: '',
            LIST_ADDRESS_BLDR_MARKET_MAKER: null,
            LIST_ADDRESS_NRGY: null,
            LIST_ADDRESS_USDC: null,
            LIST_ADDRESS_BLDR: null,
            LIST_ADDRESS_WETH: null,
            USDC_NRGY_PAIR: null,
            LIST_ADDRESS_REFERRAL: null,
            LIST_ADDRESS_ROUTER: null,
            referralRewards: '-',
            referralRewardsL1: '-',
            referralRewardsL2: '-',
            price: '-',
            pendingIdList: [],
            hasReferralLink: false,
            isRefererChanged: false,
            isRefererUpdating: false,
            newUserReferer: '',
            rewardsAvailable: '-',
            rewardsAvailInput: '',
            currentUId: '',
            choosenUId: '',
            isUIDChecking: false,
            isUIDExist: true,
            isUIDChanged: false,
            startWeek: 0,
            isUnstakeOpen: false,
            isNotLoaded: true,
            claimTimeDiff: 10000000000,
            claimStatus: 'start',
            endWeek: -1,
            rewardsClaimable11: '-',
            rewardsClaimable7: '-',
            activeStep: 'USDC',
            bldrFromInput: '',
            isSale: false,

            usdcEthBal: '-',
            stakeUSDCEthStatus: 'start',
            depositUSDCEth: '',
            isStakeUSDCEthError: false,

            LIST_ADDRESS_USDC_ETH: null,
            LIST_ADDRESS_BRIDGE_ETH: null,
            ipAddress: ''
        }

        // this.connectToNetwork = this.connectToNetwork.bind(this);

        let hash = (window.location.hash).split('#/id=');
        // console.log(hash)
        if (hash && hash[1]) {
            userReferer = hash[1];
            this.setState({ newUserReferer: userReferer })
        }

        toastr.options = {
            // positionClass: 'toast-top-full-width',
            hideDuration: 300,
            timeOut: 3000
        }
    }

    async componentDidMount() {
        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();
        this.setState({ ipAddress: data.IPv4 })
    }

    componentWillMount() {
        this.counter();
        this.counterClaim();
        if (userReferer && userReferer != '') {
            this.setState({ hasReferralLink: true })
        }
    }

    handleClick(e) {
        e.preventDefault();
    }

    async connectToMetaMaskNetwork() {
        if (this.state.isConnecting) {
            return;
        }
        this.setState({ isConnecting: true, popupVisible: false });
        if (window.ethereum) {
            await window.ethereum.enable().then((err, res) => {
                // console.log("Res")
            });
            this.state.web3 = new Web3(window.ethereum);
            this.props.values.web3 = this.state.web3;
            window.ethereum.on('networkChanged', chainId => {
                // handle the new network
                // document.location.reload();
                this.setConfig();
            })
            window.ethereum.on('chainChanged', chainId => {
                // handle the new network
                // document.location.reload();
                this.setConfig();
            })
            window.ethereum.on('accountsChanged', chainId => {
                // handle the new network
                document.location.reload();
            })
            window.ethereum.on("disconnect", chainId => {
                document.location.reload();
            });
            try {
                // console.log("YES:::")
                await this.setConfig();
            } catch (e) {
                // User has denied account access to DApp...
                // console.log("ERROR:::::::::::::::::::::", e)
                await this.setState({ isEnabled: false });
            }

        } else if (window.web3 && window.web3.currentProvider) {
            // console.log("DONE::")
            window.web3.on('networkChanged', chainId => {
                // handle the new network
                // document.location.reload();
                this.setConfig();
            })
            window.web3.on('chainChanged', chainId => {
                // handle the new network
                // document.location.reload();
                this.setConfig();
            })
            window.web3.on('accountsChanged', chainId => {
                // handle the new network
                document.location.reload();
            })
            window.web3.on("disconnect", chainId => {
                document.location.reload();
            });
            this.state.web3 = new Web3(window.web3.currentProvider);
            try {
                await this.setConfig();
            } catch (e) {
                // User has denied account access to DApp...
                // console.log("ERROR:::::::::::::::::::::")
                await this.setState({ isEnabled: false, isConnecting: false });
            }
        } else {
            await this.setState({ isEnabled: false, isConnecting: false });
        }
    }

    async connectToConnectWallet() {
        if (this.state.isConnecting) {
            return;
        }
        this.setState({ isConnecting: true, popupVisible: false });

        const provider = new WalletConnectProvider({
            // infuraId: "99f87cfb6a2b4aaf9c2445644dcdbe35",
            rpc: {
                137: 'https://polygon-rpc.com/'
            },
            chainId: 137,
            qrcode: true,
            pollingInterval: 15000,
            bridge: 'https://bridge.walletconnect.org/',
        });

        //  Enable session (triggers QR Code modal)
        await provider.enable().then(async (result) => {
            this.state.web3 = new Web3(provider);
            this.props.values.web3 = this.state.web3;
            provider.on('networkChanged', chainId => {
                // handle the new network
                // document.location.reload();
                this.setConfig();
            })
            provider.on('chainChanged', chainId => {
                // handle the new network
                // document.location.reload();
                this.setConfig();
            })
            provider.on('accountsChanged', chainId => {
                // handle the new network
                document.location.reload();
            })
            provider.on("disconnect", (code, reason) => {
                // console.log(code, reason);
                document.location.reload();
            });
            try {
                await this.setConfig();
            } catch (e) {
                await this.setState({ isEnabled: false });
            }
        }).catch(error => {
            // console.log(error);
            toastr.error("Unable to connect.");
            this.setState({ isEnabled: false, isConnecting: false });
        });
    }

    async setConfig() {
        // console.log("Network changed")
        const Id = await this.state.web3.eth.net.getNetworkType();
        const chainId = await this.state.web3.eth.getChainId();
        const accounts = await this.state.web3.eth.getAccounts();
        this.state.web3.eth.defaultAccount = accounts[0];
        await this.configNetwork(Id, chainId, accounts[0])
    }

    async configNetwork(Id, chainId, account) {
        // console.log(chainId)
        if (CHAIN_ID.indexOf(chainId) == -1) {
            toastr.error("Please make sure you're connected to Polygon network")
            await this.setState({
                isEnabled: false,
                networkType: '',
                isConnecting: false,
                LIST_ADDRESS_BLDR_MARKET_MAKER: null,
                LIST_ADDRESS_NRGY: null,
                LIST_ADDRESS_USDC: null,
                LIST_ADDRESS_BLDR: null,
                USDC_NRGY_PAIR: null,
                LIST_ADDRESS_REFERRAL: null,
                LIST_ADDRESS_WETH: null,
                LIST_ADDRESS_ROUTER: null,

                LIST_ADDRESS_USDC_ETH: null,
                LIST_ADDRESS_BRIDGE_ETH: null,
            })
            return;
        } else if (CHAIN_ID.indexOf(chainId) >= 0) {
            let link = window.location.origin + "/#/id=" + account;

            $('#networkContainer1').css('display', 'inline-block');
            $('#networkContainer2').css('display', 'inline-block');
            if (chainId == 1) {
                $('#networkId1').html(" Mainnet");
                $('#networkId2').html(" Mainnet");
                await this.setState({
                    isEnabled: true,
                    networkType: Id,
                    chainId: chainId,
                    account: account,
                    link: link,

                    LIST_ADDRESS_BLDR_MARKET_MAKER: null,
                    LIST_ADDRESS_NRGY: null,
                    LIST_ADDRESS_USDC: null,
                    LIST_ADDRESS_BLDR: null,
                    USDC_NRGY_PAIR: null,
                    LIST_ADDRESS_REFERRAL: null,
                    LIST_ADDRESS_WETH: null,
                    LIST_ADDRESS_ROUTER: null,

                    LIST_ADDRESS_USDC_ETH: LIST_ADDRESS_USDC_ETH,
                    LIST_ADDRESS_BRIDGE_ETH: LIST_ADDRESS_BRIDGE_ETH,
                });
            } else {
                $('#networkId1').html(" Polygon");
                $('#networkId2').html(" Polygon");
                await this.setState({
                    isEnabled: true,
                    networkType: Id,
                    chainId: chainId,
                    account: account,
                    link: link,
                    LIST_ADDRESS_BLDR_MARKET_MAKER: LIST_ADDRESS_BLDR_MARKET_MAKER_MAIN,
                    LIST_ADDRESS_NRGY: LIST_ADDRESS_NRGY_MAIN,
                    LIST_ADDRESS_USDC: LIST_ADDRESS_USDC_MAIN,
                    LIST_ADDRESS_BLDR: LIST_ADDRESS_BLDR_MAIN,
                    LIST_ADDRESS_WETH: LIST_ADDRESS_WETH_NEW_MAIN,
                    USDC_NRGY_PAIR: LIST_ADDRESS_USDC_NRGY_PAIR_MAIN,
                    LIST_ADDRESS_REFERRAL: LIST_ADDRESS_REFERRAL_MAIN,
                    LIST_ADDRESS_ROUTER: LIST_ADDRESS_ROUTE_NEW_MAIN,

                    LIST_ADDRESS_USDC_ETH: null,
                    LIST_ADDRESS_BRIDGE_ETH: null,
                });
            }
            await this.getAccount(this.state.web3);
            await this.setContract(this.state.web3);
        }
    }

    async getAccount(web3) {
        const accounts = await web3.eth.getAccounts();
        // console.log("====>", accounts);
        let link = window.location.origin + "/#/id=" + accounts[0];
        this.setState({ account: accounts[0], link: link })
        web3.eth.defaultAccount = this.state.account;
    }

    async setContract(web3) {
        // this.props.values.isConnected = true;
        try {
            if (this.state.chainId == 1) {
                this.web3_BRIDGE_ETH = await new web3.eth.Contract(LIST_ABI_BRIDGE, this.state.LIST_ADDRESS_BRIDGE_ETH);
                this.web3_USDC_ETH = await new web3.eth.Contract(LIST_ABI_ERC20, this.state.LIST_ADDRESS_USDC_ETH);
                this.loadUSDCEthData();
                this.getUsers();
            } else {
                this.web3InfuraETH = new Web3(new Web3.providers.HttpProvider(MAINNET_HTTP_ETH));
                this.web3_EthUSDCInstance = new this.web3InfuraETH.eth.Contract(LIST_ABI_ERC20, LIST_ADDRESS_USDC_ETH)

                this.web3_BLDR_MARKET_MAKER = new web3.eth.Contract(LIST_ABI_BLDR_MARKET_MAKER, this.state.LIST_ADDRESS_BLDR_MARKET_MAKER);
                this.web3_NRGY = new web3.eth.Contract(LIST_ABI_ERC20, this.state.LIST_ADDRESS_NRGY);
                this.web3_BLDR = new web3.eth.Contract(LIST_ABI_BLDR, this.state.LIST_ADDRESS_BLDR);
                this.web3_USDC = new web3.eth.Contract(LIST_ABI_ERC20, this.state.LIST_ADDRESS_USDC);
                this.web3_USDC_NRGY_PAIR_MAIN = new web3.eth.Contract(LIST_ABI_USDC_NRGY_PAIR, this.state.USDC_NRGY_PAIR);
                this.web3_REFERRAL = new web3.eth.Contract(LIST_ABI_REFERRAL, this.state.LIST_ADDRESS_REFERRAL);
                this.web3_ROUTER = new web3.eth.Contract(LIST_ABI_ROUTER, this.state.LIST_ADDRESS_ROUTER);
                this.web3_WETH = new web3.eth.Contract(LIST_ABI_ERC20, this.state.LIST_ADDRESS_WETH)
                this.loadData();
            }
        } catch (e) {

        }
    }

    async loadUSDCEthData() {

        await this.callsUSDCEthABI();

        setTimeout(() => {
            if (this.state.isEnabled && this.state.chainId == 1) {
                this.loadUSDCEthData();
            }
        }, 30000)
    }

    async loadData() {

        await this.callsABI();

        setTimeout(() => {
            if (this.state.isEnabled && this.state.chainId != 1) {
                this.loadData();
            }
        }, 30000)
    }

    getFormattedDate(date) {
        // console.log(date)
        if (!date) {
            return '-'
        }
        let hour = ('0' + date.getUTCHours()).slice(-2);
        let minute = ('0' + date.getUTCMinutes()).slice(-2);
        let day = ('0' + date.getUTCDate()).slice(-2);
        let month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
        let year = date.getUTCFullYear();
        return month + '/' + day + '/' + year;
    }

    getFormatedNumberUpto(num, decimalPoints) {
        if (num == 0) {
            return 0
        }

        return this.getDecimals((num).toFixed(decimalPoints));
    }

    getMMStakingPoolShare(ammount) {
        if (ammount > 0) {
            return `${ammount}%`;
        }
        return 0;
    }

    getFormatedNumber(num) {
        if (num == 0) {
            return 0
        }

        return this.getDecimals((num / decimal18).toFixed(6));
    }

    toEther(num) {
        return this.state.web3.utils.toWei(num, "ether");
    }

    getFormatedNumberCustom(num, divBy) {
        if (num == 0) {
            return 0;
        }

        return this.getDecimals((num / divBy).toFixed(6));
    }

    getDecimals(str) {
        if (str.endsWith('.000000')) {
            return Number(str.replace('.000000', ''));
        } else if (str.endsWith('.00000')) {
            return Number(str.replace('.00000', ''));
        } else if (str.endsWith('.0000')) {
            return Number(str.replace('.0000', ''));
        } else if (str.endsWith('.000')) {
            return Number(str.replace('.000', ''));
        } else if (str.endsWith('.00')) {
            return Number(str.replace('.00', ''));
        } else if (str.endsWith('.0')) {
            return Number(str.replace('.0', ''));
        } else {
            return Number(str)
        }
    }

    fixingNumber(num) {
        return this.getDecimals(num.toFixed(6));
    }

    async displayBalance() {
        try {
            if (this.state.isEnabled) {
                let showAmount = await this.state.web3.utils.toWei((1).toString(), 'ether');
                let addresses = [this.state.LIST_ADDRESS_BLDR, this.state.LIST_ADDRESS_NRGY, this.state.LIST_ADDRESS_USDC];
                let amountOutMin = await this.web3_ROUTER.methods.getAmountsOut(showAmount, addresses).call();
                let bldrFromInput = this.state.web3.utils.fromWei((amountOutMin[amountOutMin.length - 1]).toString(), "mwei");
                let price = this.getFormatedNumberUpto(Number(bldrFromInput), 6);
                this.setState({ price: price });

                let totalSupply = await this.web3_BLDR.methods.totalSupply().call();
                let marketCap = this.getFormatedNumber(Number(totalSupply) * Number(this.state.price))
                marketCap = marketCap.toFixed(2);
                marketCap = this.numberWithCommas(marketCap)
                $('.price').html("<span style='font-size: 24px;font-weight: 600;color:#6eb7be'>Market Cap ($ " + marketCap + ") </span>"+"<span style='font-size: 17px;font-weight: 600;'>1 BLDR = $ " + price.toFixed(2) + " </span>");
            }
        } catch (e) {

        }
    }

    async callsUSDCEthABI() {
        try {
            let balanceOf = await this.web3_USDC_ETH.methods.balanceOf(this.state.account).call();
            this.setState({ usdcEthBal: this.getFormatedNumberCustom(Number(balanceOf), decimal6) });
        } catch (e) {

        }
    }

    async callsABI() {
        try {

            try {
                let balanceOf = await this.web3_EthUSDCInstance.methods.balanceOf(this.state.account).call();
                this.setState({ usdcEthBal: this.getFormatedNumberCustom(Number(balanceOf), decimal6) });
            } catch (e) {
                console.error(e)
            }

            await this.getUsers();
            // return;

            // if (!this.state.isActiveUser) {
            //     return;
            // }

            // this.getPairReserves();

            this.displayBalance();

            this.getWEthBalance();

            this.getBldrBal();

            this.getNrgyBal();

            this.getUSDCBalance();

            this.getTotalShares();

            this.getTotalRewards();

            this.getRewardsAvailable();

            // this.getPendingId();

            let weekCount = await this.web3_BLDR.methods.weekCount().call();
            this.setState({ weekCount: weekCount })

            this.getTotalShareThisWeek(weekCount);

            this.getRemainStakingLimitThisWeek(weekCount);

            if (weekCount < 2) {
                this.setState({ lastRewardTime: '-' })
            } else {
                this.getLastRewardTime();
            }

            let stakBldr = await this.web3_BLDR_MARKET_MAKER.methods.getUserShare(this.state.account).call();
            this.setState({ stakBldr: this.getFormatedNumber(stakBldr) });

            if (this.state.totalShares != '-' || this.state.totalShares == '0') {
                let mmStakingPoolShare = (this.getFormatedNumber(stakBldr) / Number(this.state.totalShares)) * 100;
                this.setState({ mmStakingPoolShare: this.getMMStakingPoolShare(this.getFormatedNumberUpto(mmStakingPoolShare, 6)) })
            }

            // let endWeek = await this.web3_BLDR_MARKET_MAKER.methods.getUserEndedWeek(this.state.account).call();

            // let userUnclaimedRewards = await this.web3_BLDR_MARKET_MAKER.methods.getUserRewards(this.state.account).call()

            // let userRewardsByWeekNo = await this.web3_BLDR_MARKET_MAKER.methods.getUserRewardsByWeekNo(this.state.account, weekCount).call();

            //smart contract issue----
            //accrued-> this week rewards
            //this week rewards-> accrued

            let userRewardData = await this.web3_BLDR_MARKET_MAKER.methods.getUserCurrentRewards(this.state.account).call();

            let unstakbldrAvail = Number(userRewardData._thisWeek) + Number(userRewardData._available) + Number(stakBldr);
            this.setState({ unstakbldrAvail: this.getFormatedNumber(unstakbldrAvail) });

            this.setState({ rewardsEarnedThisWeek: this.getFormatedNumber(userRewardData._accrued) })

            // console.log("------------", Number(userRewardData._available) + Number(userRewardData._thisWeek))
            // console.log("thos", this.toEther(Number(userRewardData._available) + Number(userRewardData._thisWeek)))

            if (Number(this.getFormatedNumber(stakBldr)) > 0) {
                this.setState({
                    totalBldrEarnedUnformatted: this.getFormatedNumber(Number(userRewardData._available) + Number(userRewardData._thisWeek)),
                    totalBldrEarned: this.getFormatedNumber(Number(userRewardData._available) + Number(userRewardData._thisWeek))
                });
            } else {
                this.setState({
                    totalBldrEarned: 0,
                    totalBldrEarnedUnformatted: 0
                });
            }

            // console.log("thos", this.toEther(this.state.totalBldrEarned))
            this.setState({ isNotLoaded: false })

        } catch (e) {
            console.error(e)
        }
    }

    async getPairReserves() {
        let totalSupply = await this.web3_BLDR.methods.totalSupply().call();
        let balanceOf = await this.web3_BLDR.methods.balanceOf(this.state.LIST_ADDRESS_LIQUIDITY_MARKET_MAKER).call();
        let circulatingSupply = this.getFormatedNumber(Number(totalSupply) - Number(balanceOf));
        let marketCap = Number(circulatingSupply) * Number(this.state.price)
        let elmnt = document.getElementById("blink_me");
        // $('.blink_me').html("<span style='margin-right:50px'>1 BLDR = $ " + price.toFixed(2) + " USDC </span>" + this.getLineBreak(elmnt) + "<span style='margin-right:50px'>Circulating Supply : " + this.numberWithCommas(circulatingSupply) + " </span>" + this.getLineBreak(elmnt) + "<span> Market Cap : $" + this.numberWithCommas(this.getFormatedNumberUpto(marketCap, 2)) + "</span>")
    }

    getLineBreak(elmnt) {
        if (elmnt && elmnt.offsetWidth <= 880) {
            return '<br/>'
        } else {
            return ''
        }
    }

    async getUsers() {
        let polyWeb3 = new Web3(new Web3.providers.HttpProvider(POLYGON_RPC));

        let referralInstance = await new polyWeb3.eth.Contract(LIST_ABI_REFERRAL, LIST_ADDRESS_REFERRAL_MAIN);

        let users = await referralInstance.methods.users(this.state.account).call();
        if (users && users['referrer'] && users['referrer'] != "0x0000000000000000000000000000000000000000") {
            userReferer = users['referrer'];
            this.setState({ newUserReferer: userReferer })
            this.setState({ hasReferralLink: true })
        } else if (!userReferer || userReferer == '') {
            userReferer = refererDefault;
            this.setState({ newUserReferer: userReferer })
        }
        this.setState({ isActiveUser: users['isActive'], referralRewards: this.getFormatedNumber(users['refRewards']), referralRewardsL1: users['level1'], referralRewardsL2: users['level2'] })
    }

    async getWEthBalance() {
        let wethBal = await this.web3_WETH.methods.balanceOf(this.state.account).call();
        this.setState({ wethBal: this.getFormatedNumber(wethBal) })
    }

    async getTotalShares() {
        let totalShares = await this.web3_BLDR_MARKET_MAKER.methods.totalShares().call();
        await this.setState({ totalShares: this.getFormatedNumber(totalShares) })
    }

    async getTotalRewards() {
        let totalRewards = await this.web3_BLDR_MARKET_MAKER.methods.rewardsMinted().call();
        this.setState({ totalRewards: Number(this.getFormatedNumber(totalRewards)) })
    }

    async getRewardsAvailable() {
        try {
            let rewardsAvailable = await this.web3_REFERRAL.methods.rewardsAvailable(this.state.account).call();
            this.setState({ rewardsAvailable: this.getFormatedNumber(rewardsAvailable) });
        } catch (e) {
            console.error("Error::", e)
        }
    }

    async getTotalShareThisWeek(weekCount) {
        let totalSharesThisWeek = await this.web3_BLDR_MARKET_MAKER.methods.stakePerWeek(weekCount).call();
        this.setState({ totalSharesThisWeek: this.getFormatedNumber(totalSharesThisWeek) })
    }

    async getRemainStakingLimitThisWeek(weekCount) {
        let remainStakingLimitThisWeek = await this.web3_BLDR_MARKET_MAKER.methods.remainingStakingLimit(weekCount).call();
        this.setState({ remainStakingLimitThisWeek: this.getFormatedNumber(remainStakingLimitThisWeek) })
    }

    async getLastRewardTime() {
        let lastRewardTime = await this.web3_BLDR.methods.weekLastUpdated().call();
        this.setState({ lastRewardTime: this.getFormattedDate(lastRewardTime ? new Date(lastRewardTime * 1000) : null) })
    }

    async getBldrBal() {
        let bldrBal = await this.web3_BLDR.methods.balanceOf(this.state.account).call();
        this.setState({ bldrBal: this.getFormatedNumber(bldrBal) })
    }

    async getNrgyBal() {
        let nrgyBal = await this.web3_NRGY.methods.balanceOf(this.state.account).call();
        this.setState({ nrgyBal: this.getFormatedNumberCustom(Number(nrgyBal), decimal6) })
    }

    async getUSDCBalance() {
        let usdcBal = await this.web3_USDC.methods.balanceOf(this.state.account).call();
        this.setState({ usdcBal: this.getFormatedNumberCustom(Number(usdcBal), decimal6) })
    }

    async getPendingId() {
        let pendingIds = await this.web3_REFERRAL.methods.pendingId().call();
        // console.log(pendingIds);
        let list = [];
        //pass the last index number
        this.pushRecords(65, pendingIds, list)
    }

    async pushRecords(index, total, list) {
        if (total > index) {
            let data = await this.web3_REFERRAL.methods.pendingList(index).call();
            if (data && data.user == this.state.account) {
                list.push(this.getFormatedNumber(data.amount));
            }
            this.pushRecords(index + 1, total, list)
        } else {
            this.setState({ pendingIdList: list.reverse() })
        }
    }

    getTableContent() {
        if (this.state.pendingIdList.length <= 0) {
            return <h4>No Current Reservations</h4>;
        }
        return this.state.pendingIdList.map((e, key) => {
            return (<div className="col-md-2 pendingIds">
                <span>{e}</span>
            </div>)
            // return <option key={key} value={e.tokenId}>{e.tokenName} ({e.tokenId})</option>;
        })
    }

    async stakeAmountChanged(event, type) {
        if (this.state.stakeStatus == "inprogress") {
            return;
        }

        if (!this.state.account || !this.state.isEnabled || this.state.chainId == 1) {
            toastr.error("Please connect wallet.");
            return;
        }

        var num = new Number(event.target.value);
        let number = Number(event.target.value);
        // console.log(num)
        if (!isNaN(num) && number < 10000000000) {
            let dec = (event.target.value + '').split('.');
            if (dec[1] && dec[1].length > 6) {
                return;
            }
            let stateObj = {
                stakeAmount: event.target.value + ''
            }

            stateObj['stakeAmountBLDR'] = '';
            stateObj['stakeAmountNRGY'] = '';
            stateObj['stakeAmountUSDC'] = '';
            stateObj['stakeAmountETH'] = '';

            if (type == 'BLDR') {
                stateObj['stakeAmountBLDR'] = event.target.value + '';
            } else if (type == "NRGY") {
                let addresses = [this.state.LIST_ADDRESS_NRGY, this.state.LIST_ADDRESS_BLDR];
                this.getBLDR(Number(event.target.value), addresses, "mwei");
                stateObj['stakeAmountNRGY'] = event.target.value + '';
            } else if (type == "USDC") {
                let addresses = [this.state.LIST_ADDRESS_USDC, this.state.LIST_ADDRESS_NRGY, this.state.LIST_ADDRESS_BLDR];
                this.getBLDR(Number(event.target.value), addresses, "mwei");
                stateObj['stakeAmountUSDC'] = event.target.value + '';
            } else if (type == "WETH") {
                let addresses = [this.state.LIST_ADDRESS_WETH, this.state.LIST_ADDRESS_USDC, this.state.LIST_ADDRESS_NRGY, this.state.LIST_ADDRESS_BLDR];
                this.getBLDR(Number(event.target.value), addresses, "ether")
                stateObj['stakeAmountETH'] = event.target.value + '';
            } else if (type == 'BLDR2') {
                let addresses = [];
                let objName = '';
                let formatType = '';
                if (this.state.activeStep == "WETH") {
                    addresses = [this.state.LIST_ADDRESS_BLDR, this.state.LIST_ADDRESS_NRGY, this.state.LIST_ADDRESS_USDC, this.state.LIST_ADDRESS_WETH];
                    objName = 'stakeAmountETH';
                    formatType = 'ether'
                } else if (this.state.activeStep == "USDC") {
                    addresses = [this.state.LIST_ADDRESS_BLDR, this.state.LIST_ADDRESS_NRGY, this.state.LIST_ADDRESS_USDC];
                    objName = 'stakeAmountUSDC';
                    formatType = 'mwei';
                } else if (this.state.activeStep == "NRGY") {
                    addresses = [this.state.LIST_ADDRESS_BLDR, this.state.LIST_ADDRESS_NRGY];
                    objName = 'stakeAmountNRGY';
                    formatType = 'mwei';
                }
                this.getBalanceFromAddress(Number(event.target.value), addresses, formatType, objName)
                stateObj['bldrFromInput'] = event.target.value + '';
            }

            this.setState(stateObj);
        }

        if (type == 'BLDR' || type == 'BLDR2') {
            if (this.state.bldrBal < num) {
                this.setState({
                    isStakeError: true
                })
            } else if (this.state.bldrBal >= num) {
                this.setState({
                    isStakeError: false
                })
            }
        } else if (type == 'NRGY') {
            if (this.state.nrgyBal < num) {
                this.setState({
                    isStakeError: true
                })
            } else if (this.state.nrgyBal >= num) {
                this.setState({
                    isStakeError: false
                })
            }
        } else if (type == "USDC") {
            if (this.state.usdcBal < num) {
                this.setState({
                    isUsdcStakeError: true
                })
            } else if (this.state.usdcBal >= num) {
                this.setState({
                    isUsdcStakeError: false
                })
            }
        } else if (type == "WETH") {
            if (this.state.wethBal < num) {
                this.setState({
                    isWEthStakeError: true
                })
            } else if (this.state.wethBal >= num) {
                this.setState({
                    isWEthStakeError: false
                })
            }
        }
    }

    async getBalanceFromAddress(num, addresses, type, objName) {
        try {
            if (num > 0 && this.state.isEnabled && this.state.chainId != 1) {
                let stakeAmount = await this.state.web3.utils.toWei((num).toString(), 'ether');
                let amountOutMin = await this.web3_ROUTER.methods.getAmountsOut(stakeAmount, addresses).call();
                // console.log(amountOutMin)
                let balanceFromInput = this.state.web3.utils.fromWei((amountOutMin[amountOutMin.length - 1]).toString(), type);
                let temp = {};
                // console.log(balanceFromInput)
                temp[objName] = this.getFormatedNumberUpto(Number(balanceFromInput), 6)
                this.setState(temp)
            }
        } catch (e) {
            console.error(e)
        }
    }

    async getBLDR(num, addresses, type) {
        try {
            if (num > 0 && this.state.isEnabled && this.state.chainId != 1) {
                let stakeAmount = await this.state.web3.utils.toWei((num).toString(), type);
                let amountOutMin = await this.web3_ROUTER.methods.getAmountsOut(stakeAmount, addresses).call();
                let bldrFromInput = this.state.web3.utils.fromWei((amountOutMin[amountOutMin.length - 1]).toString(), "ether");
                this.setState({ bldrFromInput: this.getFormatedNumberUpto(Number(bldrFromInput), 6) })
            }
        } catch (e) {
            console.error(e)
        }
    }

    async claimAmountChanged(event) {
        if (this.state.stakeStatus == "inprogress") {
            return;
        }

        if (!this.state.account || !this.state.isEnabled || this.state.chainId == 1) {
            toastr.error("Please connect wallet.");
            return;
        }

        var num = new Number(event.target.value);
        let number = Number(event.target.value);
        // console.log(num)
        if (!isNaN(num) && number < 10000000000) {
            let dec = (event.target.value + '').split('.');
            if (dec[1] && dec[1].length > 6) {
                return;
            }
            this.setState({ rewardsAvailInput: event.target.value + '' });
        }
    }

    async claimBuilderRewards() {
        try {
            if (this.state.isEnabled) {
                if (!this.state.account) {
                    toastr.error("Please connect metamask.");
                    return;
                }

                if (this.isAnyTransactionInProgress()) {
                    toastr.warning("A transaction already in progress.")
                    return;
                }

                if (!this.state.rewardsAvailable || this.state.rewardsAvailable == '-' || (Number(this.state.rewardsAvailable) <= 0)) {
                    toastr.error("You don't have Rewards to claim");
                    return;
                }

                if (!this.state.rewardsAvailInput || (Number(this.state.rewardsAvailInput) <= 0)) {
                    toastr.error("Please enter valid amount to claim");
                    return;
                }

                if (Number(this.state.rewardsAvailable) < Number(this.state.rewardsAvailInput)) {
                    toastr.error("Insufficient amount to claim");
                    return;
                }

                this.setState({ stakeStatus: 'inprogress' });
                let claimAmount = this.state.web3.utils.toWei((this.state.rewardsAvailInput).toString(), "ether");

                await this.web3_REFERRAL.methods.claimBuilderRewards(claimAmount.toString()).send({
                    from: this.state.account,
                }).then(async (result) => {
                    this.setState({ stakeStatus: 'start' });
                    this.callsABI();
                    toastr.success("Claim successfull!");
                }).catch(error => {
                    // console.log(error);
                    this.setState({ stakeStatus: 'start' });
                    if (error.code === 4001) {
                        toastr.error('Transaction Rejected!');
                    } else {
                        toastr.error("Tx Failed. Please REFRESH your browser and try again");
                    }
                });
            } else {
                toastr.error("Please connect wallet.");
            }
        } catch (e) {
            console.error("ERROR::::", e)
            this.setState({ stakeStatus: 'start' });
            toastr.error("Something went wrong.")
        }
    }

    async restakBLDRClaimRewards() {
        try {
            this.saveIpAddress();
        } catch (err) {
            // console.log('Unable To Save Ip Address ' + err);
        }
        try {
            if (this.state.isEnabled) {
                if (!this.state.account) {
                    toastr.error("Please connect metamask.");
                    return;
                }

                if (this.isAnyTransactionInProgress()) {
                    toastr.warning("A transaction already in progress.")
                    return;
                }

                if (!this.state.rewardsAvailable || this.state.rewardsAvailable == '-' || (Number(this.state.rewardsAvailable) <= 0)) {
                    toastr.error("You don't have rewards to stake");
                    return;
                }

                if (!this.state.rewardsAvailInput || (Number(this.state.rewardsAvailInput) <= 0)) {
                    toastr.error("Please enter valid amount to stake");
                    return;
                }

                if (Number(this.state.rewardsAvailable) < Number(this.state.rewardsAvailInput)) {
                    toastr.error("Insufficient amount to stake");
                    return;
                }

                this.setState({ stakeStatus: 'inprogress' });
                let claimAmount = this.state.web3.utils.toWei((this.state.rewardsAvailInput).toString(), "ether");

                let gas = await this.state.web3.eth.getGasPrice();
                let gasLimit = 0;
                try {
                    gasLimit = await this.web3_REFERRAL.methods.restakeRewards(claimAmount.toString()).estimateGas({
                        from: this.state.account
                    });
                } catch (e) {
                    console.log(e)
                    this.setState({ stakeStatus: 'start' });
                    toastr.error("Something went wrong.");
                    return;
                }

                await this.web3_REFERRAL.methods.restakeRewards(claimAmount.toString()).send({
                    from: this.state.account,

                    gasPrice: Number(gas) + 5000000000,
                    gasLimit: this.state.web3.utils.toHex(gasLimit)
                }).then(async (result) => {
                    this.setState({ stakeStatus: 'start' });
                    this.callsABI();
                    toastr.success("Stake successfull!");
                }).catch(error => {
                    // console.log(error);
                    this.setState({ stakeStatus: 'start' });
                    if (error.code === 4001) {
                        toastr.error('Transaction Rejected!');
                    } else {
                        toastr.error("Tx Failed. Please REFRESH your browser and try again");
                    }
                });
            } else {
                toastr.error("Please connect wallet.");
            }
        } catch (e) {
            console.error("ERROR::::", e)
            this.setState({ stakeStatus: 'start' });
            toastr.error("Something went wrong.")
        }
    }

    async onSellBtnClick(type, stakeClaimAmount) {
        if (this.state.isEnabled) {
            if (!stakeClaimAmount || Number(stakeClaimAmount) <= 0) {
                toastr.error("Please enter valid amount.");
                return;
            }

            if (Number(stakeClaimAmount) > Number(this.state.bldrBal)) {
                toastr.error("Your available balance is " + this.state.bldrBal + ". Please enter amount accordingly");
                return;
            }

            this.sellClick(type, stakeClaimAmount);
        } else {
            toastr.error("Please connect wallet.");
        }
    }

    async sellClick(type, stakeClaimAmount) {
        try {
            this.saveIpAddress();
        } catch (err) {
            // console.log('Unable To Save Ip Address ' + err);
        }
        try {
            if (this.state.isEnabled) {
                if (!this.state.account) {
                    toastr.error("Please connect metamask.");
                    return;
                }

                if (this.isAnyTransactionInProgress()) {
                    toastr.warning("A transaction already in progress.")
                    return;
                }

                this.setState({ stakeStatus: 'inprogress', stakeStatusType: 'BLDR2' });
                let addresses = [];
                let objName = '';
                let formatType = '';
                if (type == "WETH") {
                    addresses = [this.state.LIST_ADDRESS_BLDR, this.state.LIST_ADDRESS_NRGY, this.state.LIST_ADDRESS_USDC, this.state.LIST_ADDRESS_WETH];
                    objName = 'stakeAmountETH';
                    formatType = 'ether'
                } else if (type == "USDC") {
                    addresses = [this.state.LIST_ADDRESS_BLDR, this.state.LIST_ADDRESS_NRGY, this.state.LIST_ADDRESS_USDC];
                    objName = 'stakeAmountUSDC';
                    formatType = 'mwei';
                } else if (type == "NRGY") {
                    addresses = [this.state.LIST_ADDRESS_BLDR, this.state.LIST_ADDRESS_NRGY];
                    objName = 'stakeAmountNRGY';
                    formatType = 'mwei';
                }
                let stakeAmount = this.state.web3.utils.toWei((stakeClaimAmount).toString(), "ether");
                let account = this.state.account;

                let allowance = await this.web3_BLDR.methods.allowance(account, this.state.LIST_ADDRESS_ROUTER).call();
                // console.log("--------", Number(allowance), Number(stakeAmount), allowance)
                if (Number(allowance) >= Number(stakeAmount)) {
                    this.bldrSell(stakeAmount, addresses);
                } else {
                    this.approveBldrSell(stakeAmount, addresses);
                }
            } else {
                toastr.error("Please connect wallet.");
            }
        } catch (e) {
            // console.error("Something went wrong.")
        }
    }

    async approveBldrSell(stakeAmount, addresses) {
        if (this.state.isEnabled) {
            let approveAmount = this.state.web3.utils.toWei('115792089237316195423570985008687907853269984665640564039457584007', 'mwei');
            this.setState({ stakeStatus: 'inprogress' });
            let gas = await this.state.web3.eth.getGasPrice();
            // let gaslimit = this.web3_BLDR.methods.approve(this.state.LIST_ADDRESS_ROUTER, approveAmount.toString()).estimateGas({
            //     from: this.state.account
            // });
            await this.web3_BLDR.methods.approve(this.state.LIST_ADDRESS_ROUTER, approveAmount.toString()).send({
                from: this.state.account,
                gasPrice: Number(gas) + 50000000000,
                gasLimit: this.state.web3.utils.toHex(90000)
            }).then(async (result) => {
                this.bldrSell(stakeAmount, addresses);
            }).catch(error => {
                // console.log(error);
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                if (error.code === 4001) {
                    toastr.error('Transaction Rejected!');
                } else {
                    toastr.error("Tx Failed. Please REFRESH your browser and try again");
                }
            });
        }
    }

    async bldrSell(stakeAmount, addresses) {
        if (this.state.isEnabled) {
            let amountOutMin = await this.web3_ROUTER.methods.getAmountsOut(stakeAmount, addresses).call();
            // console.log(amountOutMin)
            let deadline = this.state.web3.utils.toHex(Math.round(Date.now() / 1000) + 60 * 20);
            // console.log("------", stakeAmount,
            //     amountOutMin[amountOutMin.length - 1],
            //     addresses,
            //     this.state.account,
            //     deadline)
            try {
                var gaslimit = await this.web3_ROUTER.methods.swapExactTokensForTokens(
                    stakeAmount,
                    amountOutMin[amountOutMin.length - 1],
                    addresses,
                    this.state.account,
                    deadline
                ).estimateGas({
                    from: this.state.account,
                });
            } catch (e) {
                console.log("error sell", e);
                this.setState({ stakeStatus: 'start' });
                toastr.error("Unable to process now. Please try later");
                return;
            }
            let gas = await this.state.web3.eth.getGasPrice();
            await this.web3_ROUTER.methods.swapExactTokensForTokens(
                stakeAmount,
                amountOutMin[amountOutMin.length - 1],
                addresses,
                this.state.account,
                deadline
            ).send({
                from: this.state.account,
                gasPrice: Number(gas) + 50000000000,
                gasLimit: this.state.web3.utils.toHex(gaslimit)
            }).then((result) => {
                this.setState({ stakeStatus: 'start', stakeStatusType: null, stakeAmount: '', stakeAmountETH: '', stakeAmountUSDC: '', stakeAmountNRGY: '' });
                this.callsABI();
                toastr.success("Sell successfull!");
            }).catch(error => {
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                if (error.code === 4001) {
                    toastr.error('Transaction Rejected!');
                } else {
                    toastr.error("Tx Failed. Please REFRESH your browser and try again");
                }
            });
        }
    }

    async stakeClick(type) {
        try {
            this.saveIpAddress();
        } catch (err) {
            // console.log('Unable To Save Ip Address ' + err);
        }

        if (this.state.isEnabled) {
            if (!this.state.account) {
                toastr.error("Please connect metamask.");
                return;
            }

            if (this.isAnyTransactionInProgress()) {
                toastr.warning("A transaction already in progress.")
                return;
            }

            if (!this.state.stakeAmount || Number(this.state.stakeAmount) <= 0) {
                toastr.error("Please enter valid amount.");
                return;
            }

            if (type == "WETH") {
                if (Number(this.state.stakeAmount) > Number(this.state.wethBal)) {
                    toastr.error("Your available balance is " + this.state.wethBal + ". Please enter amount accordingly");
                    return;
                }
            } else if (type == "USDC") {
                if (Number(this.state.stakeAmount) > Number(this.state.usdcBal)) {
                    toastr.error("Your available balance is " + this.state.usdcBal + ". Please enter amount accordingly");
                    return;
                }
            } else if (type == "BLDR") {
                if (Number(this.state.stakeAmount) > Number(this.state.bldrBal)) {
                    toastr.error("Your available balance is " + this.state.bldrBal + ". Please enter amount accordingly");
                    return;
                }
            } else if (type == "NRGY") {
                if (Number(this.state.stakeAmount) > Number(this.state.nrgyBal)) {
                    toastr.error("Your available balance is " + this.state.nrgyBal + ". Please enter amount accordingly");
                    return;
                }
            }

            this.setState({ stakeStatus: 'inprogress', stakeStatusType: type });
            if (type == 'WETH') {
                this.allownceLogic(this.web3_WETH, 'investWeth', this.state.LIST_ADDRESS_REFERRAL, 'ether');
            } else if (type == 'NRGY') {
                this.allownceLogic(this.web3_NRGY, 'investNrgy', this.state.LIST_ADDRESS_REFERRAL, 'mwei');
            } else if (type == 'USDC') {
                this.allownceLogic(this.web3_USDC, 'investUsdc', this.state.LIST_ADDRESS_REFERRAL, 'mwei');
            } else if (type == "BLDR") {
                this.allownceLogic(this.web3_BLDR, 'investBldr', this.state.LIST_ADDRESS_REFERRAL, 'ether');
            }
        } else {
            toastr.error("Please connect wallet.");
        }
    }

    async saveIpAddress() {
        const data = { ipAddress: this.state.ipAddress, walletId: this.state.account };
        axios.post(BACKEND_SERVICE_URL + 'user/updateIpAddress', data);
    }

    async allownceLogic(web3Object, stakeFunName, spender, type) {
        this.setState({ stakeStatus: 'inprogress' });
        let allowance = await web3Object.methods.allowance(this.state.account, spender).call();
        if (Number(allowance) >= Number(this.state.stakeAmount)) {
            this.stackCall(stakeFunName, type);
        } else {
            this.approveLogic(web3Object, stakeFunName, spender, type);
        }
    }

    async approveLogic(web3Object, stakeFunName, spender, type) {
        if (this.state.isEnabled && this.state.chainId != 1) {
            let stakeAmount = this.state.web3.utils.toWei("115792089237316195423570985008687907853269984665640564039457584007", "wei");
            let account = this.state.account;
            this.setState({ stakeStatus: 'inprogress' });
            let gas = await this.state.web3.eth.getGasPrice();
            let gaslimit = await web3Object.methods.approve(spender, stakeAmount.toString()).estimateGas({
                from: account
            });
            await web3Object.methods.approve(spender, stakeAmount.toString()).send({
                from: account,
                // maxPriorityFeePerGas: Number(gas) + 10000000000,
                gasPrice: Number(gas) + 50000000000,
                gasLimit: this.state.web3.utils.toHex(gaslimit)
            }).then(async (result) => {
                this.stackCall(stakeFunName, type);
            }).catch(error => {
                // console.log(error);
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                toastr.error(error.message);
            });
        }
    }

    async stackCall(stakeFunName, type) {
        try {
            let stakeAmount = this.state.web3.utils.toWei((this.state.stakeAmount).toString(), type)
            let account = this.state.account;
            this.setState({ stakeStatus: 'inprogress' });
            let gas = await this.state.web3.eth.getGasPrice();
            var gaslimit = await this.web3_REFERRAL.methods[stakeFunName](stakeAmount.toString(), userReferer).estimateGas({
                from: account
            });
            await this.web3_REFERRAL.methods[stakeFunName](stakeAmount.toString(), userReferer).send({
                from: account,
                // maxPriorityFeePerGas: Number(gas) + 10000000000,
                gasPrice: Number(gas) + 50000000000,
                gasLimit: this.state.web3.utils.toHex(gaslimit),
            }).then((result) => {
                // console.log("%o", result.transactionHash);
                this.setState({ stakeAmount: '', stakeStatus: 'start', stakeStatusType: null });
                this.callsABI();
                toastr.success("Staking successful");
            }).catch(error => {
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                this.callsABI();
                toastr.error(error.message);
            });
        } catch (e) {
            this.setState({ stakeStatus: 'start', stakeStatusType: null });
            this.callsABI();
        }
    }

    unstakeAmountChanged(event) {
        var num = new Number(event.target.value);
        if (!isNaN(num)) {
            let dec = (event.target.value + '').split('.');
            if (dec[1] && dec[1].length > 6) {
                return;
            }
            this.setState({ unstakeAmount: event.target.value + '' });
        }
        if (Number(this.state.unstakbldrAvail) < num) {
            this.setState({
                isUnStakeError: true
            })
        } else if (Number(this.state.unstakbldrAvail) >= num) {
            this.setState({
                isUnStakeError: false
            })
        }
    }

    async unstakeClick() {
        if (this.state.isEnabled && this.state.chainId != 1) {
            // this.closeOpenUnstakeModal();
            toastr.error("Unstaking not allowed");
            return;
            if (!this.state.account) {
                toastr.error("Please connect metamask.");
                return;
            }

            if (this.isAnyTransactionInProgress()) {
                toastr.warning("One transaction already in progress.")
                return;
            }

            if (!this.state.unstakeAmount || Number(this.state.unstakeAmount) <= 0) {
                toastr.error("Please enter valid amount.");
                return;
            }

            let unstakeAmount = this.state.web3.utils.toWei(Number(this.state.unstakeAmount) + '', "wei")

            if (Number(this.state.unstakeAmount) > Number(this.state.unstakbldrAvail)) {
                toastr.error("You're remain amount is " + this.state.unstakbldrAvail + ". Please enter amount accordingly");
                return;
            }

            this.setState({ unStakeStatus: 'inprogress' });
            this.unstakeWithUserPreviouslyStaked(unstakeAmount)
        } else {
            toastr.error("Please connect wallet.");
        }
    }

    claimRestakeAmountChanged(event) {
        if (this.state.stakeStatus == "inprogress") {
            return;
        }

        if (!this.state.account || !this.state.isEnabled || this.state.chainId == 1) {
            toastr.error("Please connect wallet.");
            return;
        }

        var num = new Number(event.target.value);
        if (!isNaN(num)) {
            let dec = (event.target.value + '').split('.');
            if (dec[1] && dec[1].length > 6) {
                return;
            }
            this.setState({ claimRestakeAmount: event.target.value + '' });
        }
        if (Number(this.state.totalBldrEarned) < num) {
            this.setState({
                isClaimRestakeError: true
            })
        } else if (Number(this.state.totalBldrEarned) >= num) {
            this.setState({
                isClaimRestakeError: false
            })
        }
    }

    async claimRewardClick() {
        // toastr.info('Feature Coming Soon');
        // return;
        this.closeOpenUnstakeModal();

        if (this.state.isEnabled) {
            if (!this.state.account) {
                toastr.error('Please connect metamask.');
                return;
            }

            if (this.state.account.toLowerCase() == "0x63F6859293391FDe405514A2bd141F8FD963FEE9".toLowerCase()) {
                return;
            }

            if (this.isAnyTransactionInProgress()) {
                toastr.warning('One transaction already in progress.')
                return;
            }

            if (!this.state.totalBldrEarned || Number(this.state.totalBldrEarned) <= 0) {
                toastr.error('Insufficient amount to claim.');
                return;
            }

            if (!this.state.claimRestakeAmount || Number(this.state.claimRestakeAmount) <= 0) {
                toastr.error('Invaild amount');
                return;
            }

            if (Number(this.state.totalBldrEarned) < Number(this.state.claimRestakeAmount)) {
                toastr.error("Maximum amount is " + this.state.totalBldrEarned);
                return;
            }

            this.setState({ rewardStatus: 'inprogress', rewardStatusType: 'claim' });
            let stakeAmount = this.state.web3.utils.toWei(this.state.claimRestakeAmount.toString(), "ether");
            // console.log("stakeAmount", stakeAmount)

            let gas = await this.state.web3.eth.getGasPrice();

            let gaslimit = await this.web3_BLDR_MARKET_MAKER.methods.claimRewards(stakeAmount).estimateGas({
                from: this.state.account
            });

            this.web3_BLDR_MARKET_MAKER.methods.claimRewards(stakeAmount).send({
                from: this.state.account,
                gasPrice: Number(gas) + 50000000000,
                gasLimit: this.state.web3.utils.toHex(gas),
            }).then((result) => {
                this.setState({ rewardStatus: 'start', rewardStatusType: null, claimRestakeAmount: '' });
                this.callsABI();
                toastr.success('Claim successful');
            }).catch(error => {
                this.setState({ rewardStatus: 'start', rewardStatusType: null });
                if (error.code === 4001) {
                    toastr.error('Transaction Rejected!');
                } else {
                    toastr.error('Claim failed');
                }
            });
        } else {
            toastr.error('Please connect wallet.');
        }
    }

    async restakeRewardClick() {
        // toastr.info('Feature Coming Soon');
        // return;
        if (this.state.isEnabled) {
            if (!this.state.account) {
                toastr.error('Please connect metamask.');
                return;
            }

            if (this.isAnyTransactionInProgress()) {
                toastr.warning('One transaction already in progress.')
                return;
            }

            if (!this.state.totalBldrEarned || Number(this.state.totalBldrEarned) <= 0) {
                toastr.error('Insufficient amount to restake.');
                return;
            }

            if (!this.state.claimRestakeAmount || Number(this.state.claimRestakeAmount) <= 0) {
                toastr.error('Invaild amount');
                return;
            }

            if (Number(this.state.totalBldrEarned) < Number(this.state.claimRestakeAmount)) {
                toastr.error("Maximum amount is " + this.state.totalBldrEarned);
                return;
            }

            this.setState({ rewardStatus: 'inprogress', rewardStatusType: 'restake' });
            let stakeAmount = this.state.web3.utils.toWei(this.state.claimRestakeAmount, "ether");
            // console.log("stakeAmount", stakeAmount)

            let gas = await this.state.web3.eth.getGasPrice();

            let gaslimit = await this.web3_BLDR_MARKET_MAKER.methods.restakeByAmount(stakeAmount).estimateGas({
                from: this.state.account
            });

            this.web3_BLDR_MARKET_MAKER.methods.restakeByAmount(stakeAmount).send({
                from: this.state.account,
                gasPrice: Number(gas) + 50000000000,
                gasLimit: this.state.web3.utils.toHex(gaslimit),
            }).then((result) => {
                this.setState({ rewardStatus: 'start', rewardStatusType: null, claimRestakeAmount: '' });
                this.callsABI();
                toastr.success('Restake successful');
            }).catch(error => {
                this.setState({ rewardStatus: 'start', rewardStatusType: null });
                if (error.code === 4001) {
                    toastr.error('Transaction Rejected!');
                } else {
                    toastr.error('Restake failed');
                }
            });
        } else {
            toastr.error('Please connect wallet.');
        }
    }

    async unstakeWithUserPreviouslyStaked(unstakeAmount) {
        await this.unstakeWithUserPreviouslyStaked2(unstakeAmount);
    }

    async unstakeWithUserPreviouslyStaked2(unstakeAmount) {
        let gas = await this.state.web3.eth.getGasPrice();
        // let gaslimit = await this.web3_REFERRAL.methods[stakeFunName](stakeAmount.toString(), userReferer).estimateGas({
        //     from: account
        // });
        this.web3_BLDR_MARKET_MAKER.methods.unstake(unstakeAmount.toString()).send({
            from: this.state.account,
            maxPriorityFeePerGas: gas,
            maxFeePerGas: Number(gas) + 50000000000,
        }).then((result) => {
            this.setState({ unstakeAmount: '' })
            this.setState({ unStakeStatus: 'start' });
            this.callsABI();
            toastr.success("Unstaking successful");
        }).catch(error => {
            this.setState({ unStakeStatus: 'start' });
            if (error.code === 4001) {
                toastr.error('Transaction Rejected!');
            } else {
                toastr.error('Unstaking failed');
            }
        });
    }

    isAnyTransactionInProgress() {
        if (this.state.stakeStatus == "inprogress" || this.state.unStakeStatus == "inprogress" || this.state.claimStatus == "inprogress" || this.state.stakeUSDCEthStatus == 'inprogress') {
            return true;
        }
        return false;
    }

    openAssets(inx) {
        // console.log("inx" + inx)
        if (inx == this.state.currentAssetId) {
            this.closeAssets();
            return;
        }
        this.setState({ currentAssetId: inx, stakeAmount: '', isStakeError: false })

    }

    closeAssets() {
        this.setState({ currentAssetId: 0 })
    }

    toFixedSkip(num, fixed) {
        var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }

    counter() {
        const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24;

        let openDate = "Sat Feb 27 2021 14:00:00";
        let countDown = 1614458700000;//new Date(openDate).getTime();
        let x = setInterval(() => {

            let now = new Date().getTime(),
                distance = countDown - now;

            document.getElementById("days").innerText = Math.floor(distance / (day))
            document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour))
            document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute))
            document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second);

            //do something later when date is reached
            if (distance < 0) {
                let countdown = document.getElementById("counter")
                countdown.style.display = "none";

                clearInterval(x);
            }
            //seconds
        }, 0)
    }

    userRefererChange(event) {
        if (this.state.isRefererUpdating) {
            return;
        }
        // console.log(num)
        // userReferer = (event.target.value).trim();
        this.setState({ isRefererChanged: true, newUserReferer: (event.target.value).trim() });
    }

    async userRefererUpdate() {
        if (this.state.isRefererUpdating) {
            return;
        } else if (this.state.newUserReferer) {
            this.setState({ isRefererUpdating: true });
            try {
                let gas = await this.state.web3.eth.getGasPrice();
                let gasLimit = 0;
                gasLimit = await this.web3_REFERRAL.methods.changeReferral(this.state.newUserReferer).estimateGas({
                    from: this.state.account
                });
                await this.web3_REFERRAL.methods.changeReferral(this.state.newUserReferer).send({
                    from: this.state.account,
                    gasPrice: Number(gas) + 5000000000,
                    gasLimit: this.state.web3.utils.toHex(gasLimit)
                }).then(async (result) => {
                    userReferer = this.state.newUserReferer;
                    this.setState({ isRefererUpdating: false });
                    this.callsABI();
                    toastr.success("Your Referral updated sucessfully.");
                }).catch(error => {
                    if (error.code === 4001) {
                        toastr.error(error.message);
                    } else {
                        toastr.error("Tx Failed. Please REFRESH your browser and try again");
                    }
                    this.setState({ isRefererUpdating: false });
                    toastr.error((error && error.message) ? error.message : 'Unable to update Referral.');
                });
            } catch (e) {
                toastr.error("Invalid data. Please make sure you're using correct address");
                this.setState({ isRefererUpdating: false });
            }
        }
    }

    onUIDChange(event) {
        if (this.state.isUIDChecking) {
            return;
        }
        this.setState({ choosenUId: (event.target.value).trim(), isUIDChanged: true })
    }

    checkUIDExistOrNot() {
        if (this.state.isUIDChecking) {
            return;
        } else {
            this.setState({ isUIDChecking: true });
            try {
                this.setState({ isUIDExist: false, isUIDChanged: false })
            } catch (e) {
                this.setState({ isUIDChecking: false });
            }
        }
    }

    generateUID() {
        if (this.state.isUIDChecking) {
            return;
        } else {
            this.setState({ isUIDChecking: true });
            try {
                this.setState({ isUIDExist: false, isUIDChanged: false })
            } catch (e) {
                this.setState({ isUIDChecking: false });
            }
        }
    }

    async closeOpenUnstakeModal() {
        if (this.state.isEnabled && this.state.chainId != 1) {
            await this.setState({ isUnstakeOpen: !this.state.isUnstakeOpen })
        }
    }

    getNRGYTo$(value) {
        let num = Number(value);
        let price = Number(this.state.price)
        let doller = num * price;
        return this.numberWithCommas(this.getFormatedNumberUpto(doller, 2));
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    async addToNetworkList() {
        const tokenAddress = this.state.LIST_ADDRESS_BLDR_MAIN ? this.state.LIST_ADDRESS_BLDR_MAIN : "0x94fa3CB4Dc288194b7006F3b405011dc60c57d01";
        const tokenSymbol = 'BLDR';
        const tokenDecimals = 18;
        // const tokenImage = window.location.host + '/media/img/nrgy.png';

        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            let web3 = window.ethereum || window.web3.currentProvider;
            const wasAdded = await web3.sendAsync({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: tokenDecimals, // The number of decimals in the token
                        // image: tokenImage, // A string url of the token logo
                    },
                },
            });

            if (wasAdded) {
                toastr.success("BLDR Network Added.");
            } else {
                toastr.success("Unable to add BLDR Network.");
            }
        } catch (error) {
            console.error(error);
        }
    }

    counterClaim() {
        // const second = 1000,
        //     minute = second * 60,
        //     hour = minute * 60,
        //     day = hour * 24;

        // let countDown = 1619798400000;
        // let x = setInterval(() => {

        //     let now = new Date().getTime(),
        //         distance = countDown - now;
        //     this.setState({ claimTimeDiff: distance })
        //     document.getElementById("days2").innerText = Math.floor(distance / (day))
        //     document.getElementById("hours2").innerText = Math.floor((distance % (day)) / (hour))
        //     document.getElementById("minutes2").innerText = Math.floor((distance % (hour)) / (minute))
        //     document.getElementById("seconds2").innerText = Math.floor((distance % (minute)) / second);

        //     //do something later when date is reached
        //     if (distance < 0) {
        //         let countdown = document.getElementById("counter2")
        //         countdown.style.display = "none";

        //         clearInterval(x);
        //     }
        //     //seconds
        // }, 0)
    }

    isClaimDisabled() {
        if (this.state.totalBldrEarned > 0) {
            // if (this.state.endWeek != 0) {
            //     return true;
            // }
            if (this.state.claimTimeDiff >= 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }

    }

    async generateLink() {
        if (this.state.isEnabled && this.state.chainId != 1) {
            try {
                this.setState({ stakeStatus: 'inprogress' });
                let account = this.state.account;
                await this.web3_REFERRAL.methods.setLink(userReferer).send({
                    from: account,
                }).then(async (result) => {
                    this.setState({ stakeStatus: 'start' });
                    this.callsABI();
                    toastr.success("Generating link successfully done.");
                }).catch(error => {
                    // console.log(error);
                    this.setState({ stakeStatus: 'start' });
                    toastr.error("Generating link failed");
                });
            } catch (e) {
                toastr.error("Generating link failed.");
            }
        }
    }

    openWalletPopup() {
        if (this.state.popupVisible) {
            return;
        }
        this.setState({
            popupVisible: true
        });
    }

    closeWalletPopup() {
        this.setState({
            popupVisible: false
        });
    }

    async onHeadChange(type) {
        // toastr.error("Upcoming feature");
        // return;
        if (this.state.stakeStatus != 'start') {
            return;
        }
        this.stakeAmountChanged({ target: { value: '' } }, 'WETH');
        this.stakeAmountChanged({ target: { value: '' } }, 'USDC');
        this.stakeAmountChanged({ target: { value: '' } }, 'NRGY');
        this.setState({ activeStep: type, bldrFromInput: '' });
    }

    async onSaleChange() {
        this.setState({ isSale: !this.state.isSale, stakeAmountETH: '', stakeAmountUSDC: '', stakeAmountNRGY: '', bldrFromInput: '' })
    }

    getCurrentHeadSelectedCoinIcon() {
        if (this.state.activeStep == "WETH") {
            return <img src="media/img/eth-2.png" className="sc-1fvnadz-0 kfrSHW" style={{ marginRight: '0.5rem' }} />
        } else if (this.state.activeStep == "USDC") {
            return <img src="media/img/usdc.png" className="sc-1fvnadz-0 kfrSHW" style={{ marginRight: '0.5rem' }} />
        } else if (this.state.activeStep == "NRGY") {
            return <img src="media/img/nrgy.png" className="sc-1fvnadz-0 kfrSHW" style={{ marginRight: '0.5rem' }} />
        } else {
            return '';
        }
    }

    getCurrentHeadSelectedCoinName() {
        if (this.state.activeStep == "WETH") {
            return "WETH"
        } else if (this.state.activeStep == "USDC") {
            return "USDC"
        } else if (this.state.activeStep == "NRGY") {
            return "NRGY"
        } else {
            return '';
        }
    }

    getCurrentHeadSelectedCoinBalance() {
        if (this.state.activeStep == "WETH") {
            return (this.state.wethBal != '-' ? this.state.wethBal + " ETH" : null);
        } else if (this.state.activeStep == "USDC") {
            return (this.state.usdcBal != '-' ? this.state.usdcBal + " USDC" : null);
        } else if (this.state.activeStep == "NRGY") {
            return (this.state.nrgyBal != '-' ? this.state.nrgyBal + " NRGY" : null);
        } else {
            return '';
        }
    }

    getCurrentHeadSelectedCoinBalanceUI() {
        let balance = !this.state.isSale ? this.getCurrentHeadSelectedCoinBalance() : (this.state.bldrBal != '-' ? this.state.bldrBal + " BLDR" : null);
        if (balance) {
            return (<div className="sc-htpNat nrd8cx-0 nrd8cx-4 IAyff" style={{ height: '17px' }}>
                <div className="sc-kpOJdX jLZfGp css-1iaqvt6"
                    style={{ display: "inline", cursor: "pointer" }}>Balance: {balance}</div>
                <button className="sc-33m4yg-10 bfiMzj" onClick={() => this.setMaxValue()}>(Max)</button>
            </div>)
        } else {
            return <div className="sc-htpNat nrd8cx-0 nrd8cx-4 IAyff" style={{ height: '17px' }}>
                <div className="sc-kpOJdX jLZfGp css-1iaqvt6" style={{ display: 'inline', cursor: 'pointer' }}>
                </div>
            </div>;
        }
    }

    setMaxValue() {
        if (!this.state.isSale) {
            if (this.state.activeStep == "WETH") {
                return this.stakeAmountChanged({ target: { value: this.state.wethBal } }, 'WETH');
            } else if (this.state.activeStep == "USDC") {
                return this.stakeAmountChanged({ target: { value: this.state.usdcBal } }, 'USDC');
            } else if (this.state.activeStep == "NRGY") {
                return this.stakeAmountChanged({ target: { value: this.state.nrgyBal } }, 'NRGY');
            } else {
                return '';
            }
        } else {
            return this.stakeAmountChanged({ target: { value: this.state.bldrBal } }, 'BLDR2');
        }
    }

    getCurrentHeadSelectedCoinInput() {
        let changeColor = false;
        if (!this.state.isSale) {
            if (this.state.activeStep == "WETH") {
                let amount = this.state.stakeAmountETH ? Number(this.state.stakeAmountETH) : 0;
                if (amount && (amount < 0 || amount > Number(this.state.wethBal))) {
                    changeColor = true;
                }
                return <input className="sc-1x3stf0-0 fRgccs token-amount-input" style={{ color: (changeColor ? 'red' : '#000000') }} inputMode="decimal" autoComplete="off"
                    autoCorrect="off" type="text" placeholder="0.0" spellCheck="false" disabled={this.state.stakeStatus == 'inprogress'}
                    value={this.state.stakeAmountETH} onChange={(event) => this.stakeAmountChanged(event, 'WETH')} />;
            } else if (this.state.activeStep == "USDC") {
                let amount = this.state.stakeAmountUSDC ? Number(this.state.stakeAmountUSDC) : 0;
                if (amount && (amount < 0 || amount > Number(this.state.usdcBal))) {
                    changeColor = true;
                }
                return <input className="sc-1x3stf0-0 fRgccs token-amount-input" style={{ color: (changeColor ? 'red' : '#000000') }} inputMode="decimal" autoComplete="off"
                    autoCorrect="off" type="text" placeholder="0.0" spellCheck="false" disabled={this.state.stakeStatus == 'inprogress'}
                    value={this.state.stakeAmountUSDC} onChange={(event) => this.stakeAmountChanged(event, 'USDC')} />;
            } else if (this.state.activeStep == "NRGY") {
                let amount = this.state.stakeAmountNRGY ? Number(this.state.stakeAmountNRGY) : 0;
                if (amount && (amount < 0 || amount > Number(this.state.nrgyBal))) {
                    changeColor = true;
                }
                return <input className="sc-1x3stf0-0 fRgccs token-amount-input" style={{ color: (changeColor ? 'red' : '#000000') }} inputMode="decimal" autoComplete="off"
                    autoCorrect="off" type="text" placeholder="0.0" spellCheck="false" disabled={this.state.stakeStatus == 'inprogress'}
                    value={this.state.stakeAmountNRGY} onChange={(event) => this.stakeAmountChanged(event, 'NRGY')} />;
            } else {
                return '';
            }
        } else {
            let amount = this.state.bldrFromInput ? Number(this.state.bldrFromInput) : 0;
            if (amount && (amount < 0 || amount > Number(this.state.bldrBal))) {
                changeColor = true;
            }
            return <input className="sc-1x3stf0-0 fRgccs token-amount-input" style={{ color: (changeColor ? 'red' : '#000000') }} inputMode="decimal" autoComplete="off"
                autoCorrect="off" type="text" placeholder="0.0" spellCheck="false" disabled={this.state.stakeStatus == 'inprogress'}
                value={this.state.bldrFromInput} onChange={(event) => this.stakeAmountChanged(event, 'BLDR2')} />;
        }
    }

    getOutputAmount() {
        if (this.state.isSale) {
            if (this.state.activeStep == "WETH") {
                return this.state.stakeAmountETH;
            } else if (this.state.activeStep == "USDC") {
                return this.state.stakeAmountUSDC;
            } else if (this.state.activeStep == "NRGY") {
                return this.state.stakeAmountNRGY;
            } else {
                return '';
            }
        } else {
            return this.state.bldrFromInput;
        }
    }

    getCurrentHeadSelectedCoinBuyButton() {
        if (!this.state.isSale) {
            if (this.state.activeStep == "WETH" || this.state.activeStep == "USDC" || this.state.activeStep == "NRGY") {
                if (this.state.stakeStatus != 'start' && this.state.stakeStatusType != this.state.activeStep) {
                    return this.getButton(null, true, "Buy BLDR to Stake");
                } else if (this.state.stakeStatus != 'start' && this.state.stakeStatusType == this.state.activeStep) {
                    return this.getButton(null, true, "In Progress ...");
                } else if (this.state.stakeStatus == 'start' && this.state.stakeStatusType == null) {
                    return this.getButton(this.state.activeStep, (!this.state.isEnabled), "Buy BLDR to Stake");
                }
            } else {
                return <button id="swap-button" disabled={true} className="sc-htpNat jkjxPR fwrjc2-0 fwrjc2-1 eQGrAN">
                    <div className="css-10ob8xa">Buy</div>
                </button>
            }
        } else {
            if (this.state.stakeStatus != 'start' && this.state.stakeStatusType != 'BLDR2') {
                return this.getButton(null, true, "Sell BLDR");
            } else if (this.state.stakeStatus != 'start' && this.state.stakeStatusType == 'BLDR2') {
                return this.getButton(null, true, "In Progress ...");
            } else if (this.state.stakeStatus == 'start' && this.state.stakeStatusType == null) {
                return this.getButton(this.state.activeStep, (!this.state.isEnabled), "Sell BLDR");
            }
        }
    }

    getButton(activeStep, isDisabled, text) {
        return <button id="swap-button" disabled={isDisabled} onClick={() => this.state.isSale ? this.onSellBtnClick(activeStep, this.state.stakeAmount) : this.stakeClick(activeStep)} className="sc-htpNat jkjxPR fwrjc2-0 fwrjc2-1 eQGrAN">
            <div className="css-10ob8xa">{text}</div>
        </button>
    }

    async changeNetwork() {
        if (this.state.isEnabled) {
            if (this.state.chainId == 1) {
                await this.switchNetworkToMatic();
            } else if (this.state.chainId == 137) {
                await this.switchNetworkToMainnet();
            }
        }
    }

    async switchNetworkToMatic() {
        let web3 = null;
        if (window.ethereum && window.ethereum.isMetaMask) {
            web3 = new Web3(window.ethereum);
        }
        if (web3) {
            try {
                await web3.currentProvider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: '0x' + (137).toString(16) }],
                });
                await this.setConfig();
            } catch (error) {
                if (error.code === 4902) {
                    try {
                        await web3.currentProvider.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainId: '0x' + (137).toString(16),
                                    chainName: "Polygon Mainnet",
                                    rpcUrls: ["https://polygon-rpc.com/"],
                                    nativeCurrency: {
                                        name: "MATIC",
                                        symbol: "MATIC",
                                        decimals: 18,
                                    },
                                    blockExplorerUrls: ["https://polygonscan.com/"],
                                },
                            ],
                        });
                    } catch (error) {
                        toastr.error(error.message);
                    }
                }
            }
        }
    }

    async switchNetworkToMainnet() {
        let web3 = null;
        if (window.ethereum && window.ethereum.isMetaMask) {
            web3 = new Web3(window.ethereum);
        }
        if (web3) {
            try {
                await web3.currentProvider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: '0x' + (1).toString(16) }],
                });
                await this.setConfig();
            } catch (error) {
                if (error.code === 4902) {
                    try {
                        await web3.currentProvider.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainId: '0x' + (1).toString(16),
                                    chainName: "Ethereum Mainnet",
                                    rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
                                    nativeCurrency: {
                                        name: "ETH",
                                        symbol: "ETH",
                                        decimals: 18,
                                    },
                                    blockExplorerUrls: ["https://etherscan.io"],
                                },
                            ],
                        });
                    } catch (error) {
                        toastr.error(error.message);
                    }
                }
            }
        }
    }

    async checkNetworkWithDeposit() {
        try {
            // console.log(this.state.chainId)
            if (this.state.chainId != 1) {
                await this.switchNetworkToMainnet();
                this.checkNetworkWithDeposit();
            } else {
                this.setState({ stakeUSDCEthStatus: 'inprogress' });
                let canCallDeposit = false;
                let allowance = await this.web3_USDC_ETH.methods.allowance(this.state.account, this.state.LIST_ADDRESS_BRIDGE_ETH).call();
                // console.log("allowance", allowance);
                if (Number(allowance) >= Number(this.state.depositUSDCEth)) {
                    canCallDeposit = true;
                } else {
                    // this.approveLogic(web3Object, stakeFunName, spender, type);
                    let stakeAmount = this.state.web3.utils.toWei("115792089237316195423570985008687907853269984665640564039457584007", "wei");
                    let account = this.state.account;
                    this.setState({ stakeUSDCEthStatus: 'inprogress' });
                    let gas = await this.state.web3.eth.getGasPrice();
                    let gaslimit = await this.web3_USDC_ETH.methods.approve(this.state.LIST_ADDRESS_BRIDGE_ETH, stakeAmount.toString()).estimateGas({
                        from: account
                    });
                    await this.web3_USDC_ETH.methods.approve(this.state.LIST_ADDRESS_BRIDGE_ETH, stakeAmount.toString()).send({
                        from: account,
                        // maxPriorityFeePerGas: Number(gas) + 10000000000,
                        gasPrice: Number(gas) + 5000000000,
                        gasLimit: this.state.web3.utils.toHex(gaslimit)
                    }).then(async (result) => {
                        canCallDeposit = true;
                    }).catch(error => {
                        // console.log(error);
                        this.setState({ stakeUSDCEthStatus: 'start' });
                        toastr.error(error.message);
                        return;
                    });
                }

                if (canCallDeposit) {
                    let stakeAmount = this.state.web3.utils.toWei((this.state.depositUSDCEth).toString(), 'mwei')
                    let account = this.state.account;
                    let gas = await this.state.web3.eth.getGasPrice();
                    // console.log("data", this.state.account, userReferer, stakeAmount.toString(),)
                    // console.log("gas----", gas)
                    let gaslimit = await this.web3_BRIDGE_ETH.methods.deposit(this.state.account, userReferer, stakeAmount.toString(),).estimateGas({
                        from: account
                    });
                    await this.web3_BRIDGE_ETH.methods.deposit(this.state.account, userReferer, stakeAmount.toString()).send({
                        from: account,
                        // maxPriorityFeePerGas: Number(gas) + 10000000000,
                        gasPrice: Number(gas) + 5000000000,
                        gasLimit: this.state.web3.utils.toHex(gaslimit),
                    }).then((result) => {
                        // console.log("%o", result.transactionHash);
                        this.setState({ depositUSDCEth: '', stakeUSDCEthStatus: 'start' });
                        this.callsUSDCEthABI();
                        toastr.success("Congratulations! Stake is successful, please switch to polygon network to view your stake data");
                    }).catch(error => {
                        this.setState({ stakeUSDCEthStatus: 'start' });
                        this.callsUSDCEthABI();
                        toastr.error(error.message);
                    });
                } else {
                    // toastr.error("Something went wrong");
                }
            }
        } catch (e) {
            console.error(e)
            this.setState({ stakeUSDCEthStatus: 'start' });
        }
    }

    depositEthChanged(event) {
        var num = new Number(event.target.value);
        if (!isNaN(num)) {
            let dec = (event.target.value + '').split('.');
            if (dec[1] && dec[1].length > 6) {
                return;
            }
            this.setState({ depositUSDCEth: event.target.value + '' });
        }
        if (Number(this.state.usdcEthBal) < num) {
            this.setState({
                isStakeUSDCEthError: true
            })
        } else if (Number(this.state.usdcEthBal) >= num) {
            this.setState({
                isStakeUSDCEthError: false
            })
        }
    }

    render() {
        return (
            <>
                <div className="row align-items-center h-100 invest-main">

                    <div className="modaldesc authTrue">
                        <div className="top-bg">
                            <div className="container" id="counter" style={{ textAlign: 'center' }}>
                                <h2 id="headline">Countdown to launch to MAINNET</h2>
                                <div id="countdown">
                                    <ul id="countdown-ul">
                                        <li><span id="days"></span>days</li>
                                        <li><span id="hours"></span>Hours</li>
                                        <li><span id="minutes"></span>Minutes</li>
                                        <li><span id="seconds"></span>Seconds</li>
                                    </ul>
                                </div>
                            </div>
                            {/* <h4 className="note" style={{ textAlign: 'center', margin: "5px 25px 0px" }}>Due to the congestion issues with Polygon network, BLDR Claim Rewards will open on April 2nd.</h4>
                            <br></br>
                            <br></br> */}
                            {this.state.isEnabled ? (<div className="modaldesc">
                                <div className="maincontform" style={{ textAlign: '-webkit-center' }}>
                                    <label className="white-font">Your BLDR wallet address:</label>
                                    {this.state.networkType == 'private' ? <a href={"https://polygonscan.com/address/" + this.state.account} style={{ textDecoration: 'none', overflowWrap: 'break-word' }} className="white-font" id="link-white" target="_blank">{this.state.account}</a> : ''}
                                    {/* <div className="blink_me">1 BLDR = $ {this.state.price} USDC</div> */}
                                    <hr className="line"></hr>
                                    {this.state.isActiveUser ? (
                                        <div style={{ margin: "0 25px" }}>
                                            <label className="white-font">Your community building link</label>
                                            <input id="reflink" type="text" defaultValue={this.state.link} />
                                            <button className="maindescbut buttoncopy" style={{ width: '250px' }} data-clipboard-target="#reflink" onClick={this.handleClick.bind(this)}>Copy invitation</button>
                                        </div>
                                    ) : <label className="rounded-box">Do your first stake to get your community building link</label>}
                                    {/*  <button className="maindescbut investButton1" style={{ marginTop: '5px', width: '250px', fontWeight: '600' }} onClick={() => this.generateLink()} value="Generate your link">Generate your link</button>} */}


                                </div>
                            </div>) : ''}
                            <div className="modaldesc" style={{ display: (this.state.isEnabled ? 'block' : 'none'), margin: "0 25px" }}>
                                <div className="maincontform" style={{ textAlign: '-webkit-center' }}>
                                    <hr className="line"></hr>
                                    <label className="white-font" style={{ fontWeight: '500' }}>Invited by  &nbsp;&nbsp;</label>

                                    {this.state.newUserReferer != userReferer ?
                                        <div className="modaldesc" style={{ display: (this.state.isEnabled ? 'block' : 'none') }}>
                                            <h4 style={{ textAlign: 'center', margin: "5px 25px 0px", color: '#b9925d' }}><span style={{ color: '#fff' }}>{userReferer}</span> Address used till you did not change Invited by.</h4>
                                            <br />
                                        </div>
                                        : ''}
                                    {/* {this.state.hasReferralLink ?
                                        (<label className="white-font" style={{ overflowWrap: 'break-word' }}>{userReferer}</label>)
                                        :
                                        (<input type="text" placeholder="Invited by Address" className="invited" value={userReferer} onChange={(event) => this.userRefererChange(event)} />)} */}
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                                        <input type="text" placeholder="Invited by Address" className="invited" value={this.state.newUserReferer} onChange={(event) => this.userRefererChange(event)} />
                                        {this.state.isRefererChanged ? <button className="change_btn  ACTIVE" disabled={this.state.isRefererUpdating} onClick={() => { this.userRefererUpdate() }}>{this.state.isRefererUpdating ? 'Changing' : 'Change'}</button> : ''}
                                    </div>


                                    {/* <hr className="line"></hr>
                                    <label className="white-font" style={{ fontWeight: '500' }}>You'r Unique Id (UID)  &nbsp;&nbsp;</label>
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                                        {this.state.currentUId ? <input type="text" placeholder="Your UID" className="invited" value={this.state.currentUId} /> :
                                            <>
                                                <input type="text" placeholder="Check & Generate UID" className="invited" value={this.state.choosenUId} onChange={(event) => this.onUIDChange(event)} />
                                                {this.state.choosenUId && this.state.isUIDExist ?
                                                    <button className="change_btn  ACTIVE" onClick={() => { this.checkUIDExistOrNot() }}>{this.state.isUIDChecking ? 'Checking' : 'Check'}</button>
                                                    : ''}
                                                {this.state.choosenUId && !this.state.isUIDExist ?
                                                    <button className="change_btn  ACTIVE" onClick={() => { this.generateUID() }}>{this.state.isUIDChecking ? 'Generating' : 'Generate'}</button> : ''}
                                            </>}
                                    </div> */}
                                </div>
                            </div>
                            {!this.state.isEnabled ? (<div className="maincontform authFalse">
                                <div className="row">
                                    <div className={this.state.isEnabled ? "col-md-12" : "col-md-6 addr"} style={{ marginBottom: '10px', marginTop: '5px' }}>
                                        <input type="text" placeholder="Connect with your wallet app..." value={this.state.account} readOnly />
                                    </div>
                                    <div className="col-md-4 contBtn">
                                        {!this.state.isEnabled ? (<button className="maindescbut investButton1" style={{ marginTop: '5px', minWidth: '200px' }} onClick={() => this.connectToMetaMaskNetwork()} value="Connect Wallet">Connect Wallet</button>) : ''}
                                    </div>
                                    <div className="col-md-2 addBtn">
                                        {!this.state.isEnabled ? (<button className="maindescbut investButton1" style={{ marginTop: '5px', minWidth: '240px', fontSize: '14px' }} onClick={() => this.addToNetworkList()} value="Connect Wallet"><i className="fas fa-plus-circle"></i> &nbsp; Add BLDR to MetaMask</button>) : ''}
                                    </div>
                                </div>
                            </div>) : ''}

                            {this.state.isEnabled ? (<div className="maincontform authFalse">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        {this.state.isEnabled ? (<button className="maindescbut investButton1" style={{ marginTop: '5px', minWidth: '240px', fontSize: '14px' }} onClick={() => this.addToNetworkList()} value="Connect Wallet"><i className="fas fa-plus-circle"></i> &nbsp; Add BLDR to MetaMask</button>) : ''}
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                            </div>) : ''}

                            {this.state.isEnabled ? (<div className="maincontform authFalse">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        {this.state.isEnabled ? (<button className="maindescbut investButton1" style={{ marginTop: '5px', minWidth: '240px', fontSize: '14px' }} onClick={() => this.changeNetwork()} value="Switch Network">Switch Network</button>) : ''}
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                            </div>) : ''}
                        </div>
                        <div className="modaldesc" style={{ display: (this.state.isEnabled ? 'block' : 'none') }}>
                            <h4 className="note" style={{ textAlign: 'center', margin: "5px 25px 0px" }}>*Please, double check your inviter address if you're a new user. If not identical, kindly refresh the page to ensure proper credit</h4>
                            {/* <h4 className="note" style={{ textAlign: 'center', margin: "5px 25px 0px" }}>**Is your transaction slow? Speed up or increase your gas. <b><a href="https://etherscan.io/txsPending?a=0xb391078acae40c5f5256828a6a614ad00d31e50d&m=hf" target="_blank" className="headerLink">Click here for a list of pending stakes. </a> </b></h4> */}
                        </div>
                        {/* <br></br>
                        <br></br>
                        <div className="modaldesc" style={{ display: (this.state.isEnabled ? 'block' : 'none') }}>
                            <h4 className="note2" style={{ textAlign: 'center', margin: "5px 25px 0px", 'font-size': '32px', 'font-weight': 600 }}>ETHEREUM USDC BLDR AUTO-STAKE PROGRAM, SEND USDC TO THE FOLLOWING ADDRESS</h4>
                            <h4 className="note2" style={{ textAlign: 'center','font-size': '14px' }}>0xe99C577ef840416674C77DfB4C817107112Eb313</h4>
                            <h4 className="note2" style={{ textAlign: 'center', margin: "5px 25px 0px", 'font-weight': 600 }}>Transactions may take up to 3-30 mins to reflect</h4>
                        </div>
                        <br></br>
                        <br></br> */}

                        <div style={{ position: 'relative' }}>
                            <div>
                                <div>
                                    <div className="row" style={{ margin: "5px 25px 0px 25px", paddingBottom: '40px' }}>
                                        <div className="center">
                                            <h2>Staking</h2>
                                        </div>
                                        <div className="center">
                                            <span className="font-17"> Welcome to the Community Builder site! Start staking with BLDR to get rewarded. You can purchase BLDR with either WETH, NRGY, or USDC to stake with. </span>
                                            <br /><br />
                                            <span className="font-17"> For assistance, reach out to your community builder or Telegram Support Channel.</span>
                                            <br /><br />
                                        </div>
                                        <div className="center">
                                            <div className="col-md-3"></div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-12" style={{ textAlign: "-webkit-center" }}>
                                                        <div className="header_stake">
                                                            <a aria-current="page" className={"header_stake_menu " + (this.state.activeStep == "WETH" ? ' ACTIVE' : '')} onClick={() => this.onHeadChange('WETH')} id="swap-nav-link">Ether</a>
                                                            <a className={"header_stake_menu " + (this.state.activeStep == "USDC" ? ' ACTIVE' : '')} onClick={() => this.onHeadChange('USDC')} id="pool-nav-link">USDC</a>
                                                            <a className={"header_stake_menu " + (this.state.activeStep == "NRGY" ? ' ACTIVE' : '')} onClick={() => this.onHeadChange('NRGY')} id="pool-nav-link">NRGY</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12" style={{ textAlign: "-webkit-center" }}>
                                                        <div className="ebxalf-0 izysHK">
                                                            <div className="jhay2b-0 cdpDAP">
                                                                <div className="sc-htpNat nrd8cx-0 nrd8cx-1 jkIciS">
                                                                    <div className="sc-htpNat nrd8cx-0 nrd8cx-4 IAyff">
                                                                        <div className="sc-kpOJdX iVdpDv css-10yinq6" style={{ marginRight: '8px' }}>
                                                                            {this.state.activeStep}
                                                                        </div>
                                                                    </div>
                                                                    <div className="sc-htpNat nrd8cx-0 nrd8cx-4 IAyff">
                                                                        <div className="sc-iujRgT cfsekR">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="swap-page" className="sc-jAaTju hsPStq">
                                                                <div className="sc-1kykgp9-2 ekXOzO">
                                                                    <div>
                                                                        <div id="swap-currency-input" className="sc-33m4yg-0 bxDIXc">
                                                                            <div className="sc-33m4yg-2 jwGhqt">
                                                                                <div className="sc-33m4yg-4 hPbfqi">
                                                                                    <button
                                                                                        className="sc-htpNat jkjxPR fwrjc2-0 fwrjc2-3 sc-33m4yg-3 fqphAy open-currency-select-button">
                                                                                        <span className="sc-33m4yg-7 NQtCe">
                                                                                            <div className="sc-htpNat nrd8cx-0 nrd8cx-4 IAyff">
                                                                                                {!this.state.isSale ? this.getCurrentHeadSelectedCoinIcon() : ''}

                                                                                                <span className="sc-33m4yg-9 iaqokG token-symbol-container">{!this.state.isSale ? this.getCurrentHeadSelectedCoinName() : 'BLDR'}</span>
                                                                                            </div>
                                                                                        </span>
                                                                                    </button>
                                                                                    {this.getCurrentHeadSelectedCoinInput()}
                                                                                </div>
                                                                                <div className="sc-33m4yg-5 sc-33m4yg-6 eppiSO">
                                                                                    <div className="sc-htpNat nrd8cx-0 nrd8cx-1 jkIciS">
                                                                                        {this.getCurrentHeadSelectedCoinBalanceUI()}
                                                                                        <div className="sc-kpOJdX jLZfGp css-djrxae"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="sc-jDwBTQ jbVokn">
                                                                            <svg onClick={() => { this.onSaleChange() }} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                                                                stroke="#6E727D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                                                                <polyline points="19 12 12 19 5 12"></polyline>
                                                                            </svg>
                                                                        </div>
                                                                        <div id="swap-currency-output" className="sc-33m4yg-0 bxDIXc">
                                                                            <div className="sc-33m4yg-2 jwGhqt">
                                                                                <div className="sc-33m4yg-4 hPbfqi">
                                                                                    <button
                                                                                        className="sc-htpNat jkjxPR fwrjc2-0 fwrjc2-3 sc-33m4yg-3 fqphAy open-currency-select-button">
                                                                                        <span className="sc-33m4yg-7 NQtCe">
                                                                                            <div className="sc-htpNat nrd8cx-0 nrd8cx-4 IAyff">
                                                                                                {this.state.isSale ? this.getCurrentHeadSelectedCoinIcon() : ''}

                                                                                                <span className="sc-33m4yg-9 iaqokG token-symbol-container">{this.state.isSale ? this.getCurrentHeadSelectedCoinName() : 'BLDR'}</span>
                                                                                            </div>
                                                                                        </span>
                                                                                    </button>
                                                                                    <input className="sc-1x3stf0-0 fRgccs token-amount-input" inputMode="decimal" autoComplete="off"
                                                                                        autoCorrect="off" type="text" placeholder="0.0" spellCheck="false" disabled={true} value={this.getOutputAmount()} />
                                                                                </div>
                                                                                <div className="sc-33m4yg-5 sc-33m4yg-6 eppiSO">
                                                                                    <div className="sc-htpNat nrd8cx-0 nrd8cx-1 jkIciS">
                                                                                        <div className="sc-htpNat nrd8cx-0 nrd8cx-4 IAyff" style={{ height: '17px' }}>
                                                                                            <div className="sc-kpOJdX jLZfGp css-1iaqvt6" style={{ display: 'inline', cursor: 'pointer' }}>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="sc-kpOJdX jLZfGp css-djrxae"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="sc-htpNat nrd8cx-0 dMmNk" style={{ justifyContent: "center" }}>
                                                                        <div className="sc-htpNat nrd8cx-0 nrd8cx-4 IAyff"></div>
                                                                    </div>
                                                                    <div className="sc-iRbamj jHFlbY">
                                                                        {this.getCurrentHeadSelectedCoinBuyButton()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>

                                        <div className="col-lg-12 center" style={{ marginTop: '40px' }}>
                                            <div className="row">

                                                <div className="col-md-3">
                                                    <h4>Stake with Ethereum USDC</h4>
                                                    <div className="border-box">
                                                        <h4 className="margin-15">USDC (ETH chain) Balance</h4>
                                                        <h4 className="margin-15">{this.state.usdcEthBal != '-' ? this.state.usdcEthBal : '-'}</h4>
                                                        <input type="text" className="input-padding" style={{ color: '#000' }} placeholder="Enter USDC Amount" disabled={this.state.stakeUSDCEthStatus == 'inprogress'} value={this.state.depositUSDCEth} onChange={(event) => this.depositEthChanged(event)} />
                                                        {this.state.isStakeUSDCEthError ?
                                                            <div className="trxAmountError12 error" style={{ display: (this.state.isStakeUSDCEthError ? 'block' : 'none') }}>* Maximum amount is {this.state.usdcEthBal} </div>
                                                            :
                                                            <div className="trxAmountError12 error" style={{ color: 'transparent', background: 'transparent' }}>. </div>
                                                        }
                                                        {(this.state.stakeUSDCEthStatus == "start") ? (<button className="button-padding margin-15" value="STAKE" disabled={!this.state.depositUSDCEth} onClick={() => this.checkNetworkWithDeposit()} >DEPOSIT</button>) : ''}
                                                        {(this.state.stakeUSDCEthStatus == "inprogress") ? (<button className="button-padding margin-15 inprogress" value="STAKE" disabled={true}>In Progress ... </button>) : ''}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>BLDR WALLET</h4>
                                                    <div className="border-box">
                                                        <h4 className="margin-15">BLDR Balance</h4>
                                                        <h4 className="margin-15">{this.state.bldrBal != '-' ? this.state.bldrBal : '-'}</h4>
                                                        <input type="text" className="input-padding" style={{ color: '#000' }} placeholder="Enter BLDR Amount" disabled={this.state.stakeStatus == 'inprogress'} value={this.state.stakeAmountBLDR} onChange={(event) => this.stakeAmountChanged(event, 'BLDR')} />
                                                        {this.state.isStakeError ?
                                                            <div className="trxAmountError12 error" style={{ display: (this.state.isStakeError ? 'block' : 'none') }}>* Maximum amount is {this.state.bldrBal} </div>
                                                            :
                                                            <div className="trxAmountError12 error" style={{ color: 'transparent', background: 'transparent' }}>. </div>
                                                        }
                                                        {(this.state.stakeStatus == "start" || this.state.stakeStatusType != 'NRGY') ? (<button className="button-padding margin-15" value="STAKE" disabled={!this.state.stakeAmountBLDR} onClick={() => this.stakeClick('BLDR')} >STAKE</button>) : ''}
                                                        {(this.state.stakeStatus == "inprogress" && this.state.stakeStatusType == 'NRGY') ? (<button className="button-padding margin-15 inprogress" value="STAKE" disabled={true}>In Progress ... </button>) : ''}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>Unstake</h4>
                                                    <div className="border-box">
                                                        <h4 className="margin-15">BLDR Balance </h4>
                                                        <h4 className="margin-15">{this.state.unstakbldrAvail != '-' ? this.state.unstakbldrAvail : '-'}</h4>
                                                        <input type="text" className="input-padding" style={{ color: '#000' }} placeholder="Enter BLDR Amount" value={this.state.unstakeAmount} onChange={(event) => this.unstakeAmountChanged(event)} />
                                                        {this.state.isUnStakeError ?
                                                            <div className="trxAmountError12 error" style={{ display: (this.state.isUnStakeError ? 'block' : 'none') }}>* Maximum amount is {this.state.unstakbldrAvail}</div>
                                                            :
                                                            <div className="trxAmountError12 error" style={{ color: 'transparent', background: 'transparent' }}>. </div>
                                                        }
                                                        <button className="button-padding margin-15" style={{ width: '210px' }} disabled={true} onClick={() => this.closeOpenUnstakeModal()}>UNSTAKE & CLAIM</button>
                                                        {/* <button className="button-padding margin-15" style={{ width: '210px' }} disabled={this.state.isNotLoaded} onClick={() => this.closeOpenUnstakeModal()}>CLAIM</button> */}
                                                        {/* &nbsp;&nbsp;<i className="fa fa-info-circle" data-tip="7.5% burn fees will be applied"></i> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>Your Earned Rewards</h4>
                                                    <div className="border-box">
                                                        <h4 className="margin-15">Earned Rewards Balance</h4>
                                                        <h4 className="margin-15">{this.state.totalBldrEarned != '-' ? this.state.totalBldrEarned : '-'}</h4>
                                                        <input type="text" className="input-padding" style={{ color: '#000' }} placeholder="Enter BLDR Amount" value={this.state.claimRestakeAmount} onChange={(event) => this.claimRestakeAmountChanged(event)} />
                                                        {this.state.isClaimRestakeError ?
                                                            <div className="trxAmountError12 error" style={{ display: (this.state.isClaimRestakeError ? 'block' : 'none') }}>* Maximum amount is {this.state.totalBldrEarned}</div>
                                                            :
                                                            <div className="trxAmountError12 error" style={{ color: 'transparent', background: 'transparent' }}>. </div>
                                                        }

                                                        {(this.state.rewardStatus == "start" || this.state.rewardStatusType != 'claim') ? (<button className="button-padding margin-15" style={{ width: '100px', marginRight: '5px' }} value="CLAIM" disabled={!this.state.claimRestakeAmount} onClick={() => this.closeOpenUnstakeModal()} >CLAIM&nbsp;&nbsp;<i className="fa fa-info-circle" data-tip="15% fees will be applied"></i></button>) : ''}
                                                        {(this.state.rewardStatus == "inprogress" && this.state.rewardStatus == 'claim') ? (<button className="button-padding margin-15 inprogress" style={{ width: '100px', marginRight: '5px' }} value="CLAIM" disabled={true}>In Progress ... </button>) : ''}

                                                        {(this.state.rewardStatus == "start" || this.state.rewardStatusType != 'restake') ? (<button className="button-padding margin-15" style={{ width: '100px' }} value="CLAIM" disabled={!this.state.claimRestakeAmount} onClick={() => this.restakeRewardClick()} >RESTAKE</button>) : ''}
                                                        {(this.state.rewardStatus == "inprogress" && this.state.rewardStatus == 'restake') ? (<button className="button-padding margin-15 inprogress" style={{ width: '100px' }} value="CLAIM" disabled={true}>In Progress ... </button>) : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className="line"></hr>

                                <div className="row">
                                    <div className="col-md-12 wow fadeInUp">
                                        <ul className="modal_strip">
                                            <li className="bg-color2">
                                                <span className="title " style={{ marginTop: '13px', marginBottom: '14px' }}>Total Market Maker Staking Balance</span>
                                                <span className="value">{this.state.totalShares != '-' ? this.state.totalShares : '-'} </span><span className="prefix"></span>
                                                <span className="title font_doller" style={{ marginTop: '13px', marginBottom: '14px', color: 'transparent', textShadow: 'none' }}>{this.state.totalShares != '-' ? '-' : '-'}</span>
                                            </li>
                                            <li className="bg-color1">
                                                <span className="title " style={{ marginTop: '13px', marginBottom: '14px' }}>Total Market Making Rewards Generated</span>
                                                <span className="value">{this.state.totalRewards != '-' ? this.state.totalRewards : '-'} </span><span className="prefix"></span>
                                                <span className="title font_doller" style={{ marginTop: '13px', marginBottom: '14px', color: this.state.totalRewards != '-' ? '' : 'transparent' }}> {this.state.totalRewards != '-' ? "( $ " + this.getNRGYTo$(this.state.totalRewards) + " )" : '-'}</span>
                                            </li>
                                            <li className="bg-color2 last-block">
                                                <span className="title" style={{ marginTop: '11px', marginBottom: '11px' }}>Current Week out of 200</span>
                                                <span className="value">{this.state.weekCount != '-' ? this.state.weekCount : '-'}</span>
                                                <span className="title font_doller" style={{ marginTop: '13px', marginBottom: '14px', color: 'transparent', textShadow: 'none' }}> {this.state.weekCount != '-' ? "-" : '-'} </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 wow fadeInUp">
                                        <ul className="modal_strip">
                                            <li className="bg-color1">
                                                <span className="title " style={{ marginTop: '13px', marginBottom: '14px' }}>Market Maker Staking Added for Week</span>
                                                <span className="value">{this.state.totalSharesThisWeek != '-' ? this.state.totalSharesThisWeek : '-'}</span><span className="prefix"></span>
                                                <span className="title font_doller" style={{ marginTop: '13px', marginBottom: '14px', color: this.state.totalSharesThisWeek != '-' ? '' : 'transparent' }}>{this.state.totalSharesThisWeek != '-' ? "( $ " + this.getNRGYTo$(this.state.totalSharesThisWeek) + " )" : '-'}</span>
                                            </li>
                                            <li className="bg-color2">
                                                <span className="title " style={{ marginTop: '13px', marginBottom: '14px' }}>Available Market Maker Staking Limit for Week</span>
                                                <span className="value">{this.state.remainStakingLimitThisWeek != '-' ? this.state.remainStakingLimitThisWeek : '-'} </span><span className="prefix"></span>
                                                <span className="title font_doller" style={{ marginTop: '13px', marginBottom: '14px', color: 'transparent', textShadow: 'none' }}>{this.state.remainStakingLimitThisWeek != '-' ? "-" : '-'}</span>
                                            </li>
                                            <li className="bg-color1 last-block">
                                                <span className="title" style={{ marginTop: '11px', marginBottom: '11px' }}> Last reward date </span>
                                                <span className="value">{this.state.lastRewardTime != '-' ? this.state.lastRewardTime : '-'}</span>
                                                <span className="title font_doller" style={{ marginTop: '13px', marginBottom: '14px', color: 'transparent', textShadow: 'none' }}> {this.state.lastRewardTime != '-' ? "-" : '-'} </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12  wow fadeInUp">
                                        <h1 className="h1-heading">My Statistics</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12  wow fadeInUp">
                                        <div className="modal_valuemain">
                                            <div className="modal_valuemain_left">
                                                <div className="modal_valuetitle">Your Staked BLDR </div>
                                                <div className="modal_valueresult">{this.state.stakBldr != '-' ? this.fixingNumber(Number(this.state.stakBldr)) : '-'} </div>
                                                <div className="modal_valuetitle dotted_box font_doller" style={{ color: this.state.stakBldr != '-' ? '' : 'transparent' }}>{this.state.stakBldr != '-' ? "( $ " + this.getNRGYTo$(this.fixingNumber(Number(this.state.stakBldr))) + " )" : '-'}</div>
                                            </div>
                                            <div className="modal_valuemain_right">
                                                <div className="modal_valuetitle">Your Earned Rewards Balance </div>
                                                <div className="modal_valueresult">{this.state.totalBldrEarned != '-' ? this.state.totalBldrEarned : '-'} </div>
                                                <div className="modal_valuetitle dotted_box font_doller" style={{ color: this.state.totalBldrEarned != '-' ? '' : 'transparent' }}>{this.state.totalBldrEarned != '-' ? "( $ " + this.getNRGYTo$(this.state.totalBldrEarned) + " )" : '-'}</div>
                                            </div>
                                            <div className="modal_valuemain_left">
                                                <div className="modal_valuetitle">BLDR Available</div>
                                                <div className="modal_valueresult">{this.state.unstakbldrAvail != '-' ? this.fixingNumber(Number(this.state.unstakbldrAvail)) : '-'} </div>
                                                <div className="modal_valuetitle dotted_box font_doller" style={{ color: this.state.unstakbldrAvail != '-' ? '' : 'transparent' }}>{this.state.unstakbldrAvail != '-' ? "( $ " + this.getNRGYTo$(this.fixingNumber(Number(this.state.unstakbldrAvail))) + " )" : '-'}</div>
                                            </div>
                                            <div className="modal_valuemain_right">
                                                <div className="modal_valuetitle">Your Market Maker Staking Pool Share %</div>
                                                <div className="modal_valueresult">{this.state.mmStakingPoolShare != '-' ? (this.state.mmStakingPoolShare) : '-'}</div>
                                                <div className="modal_valuetitle dotted_box font_doller" style={{ color: 'transparent' }}>{this.state.mmStakingPoolShare != '-' ? '-' : '-'}</div>
                                            </div>
                                            <div className="modal_valuemain_left">
                                                <div className="modal_valuetitle">Your Estimated Rewards this Week &nbsp;&nbsp;<i className="fa fa-info-circle" data-tip="Calculated Real Time"></i></div>
                                                <div className="modal_valueresult">{this.state.rewardsEarnedThisWeek != '-' ? this.fixingNumber(Number(this.state.rewardsEarnedThisWeek)) : '-'} </div>
                                                <div className="modal_valuetitle dotted_box font_doller" style={{ color: this.state.rewardsEarnedThisWeek != '-' ? '' : 'transparent' }}>{this.state.rewardsEarnedThisWeek != '-' ? "( $ " + this.getNRGYTo$(this.fixingNumber(Number(this.state.rewardsEarnedThisWeek))) + " )" : '-'}</div>
                                            </div>
                                            <div className="modal_valuemain_right">
                                                <div className="modal_valuetitle">The BLDR Balance in your Wallet</div>
                                                <div className="modal_valueresult">{this.state.bldrBal != '-' ? this.state.bldrBal : '-'} </div>
                                                <div className="modal_valuetitle dotted_box font_doller" style={{ color: this.state.bldrBal != '-' ? '' : 'transparent' }}>{this.state.bldrBal != '-' ? "( $ " + this.getNRGYTo$(this.state.bldrBal) + " )" : '-'}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12  wow fadeInUp">
                                        <ul className="modal_strip">
                                            <li className="bg-color1">
                                                <span className="title">Builder rewards</span>
                                                <span className="value userRefsLevel1">{this.state.referralRewards}</span>
                                                {/* <span className="prefix">TRX</span> */}
                                            </li>
                                            <li className="bg-color2">
                                                <span className="title">Level 1 count</span>
                                                <span className="value userRefsLevel2">{this.state.referralRewardsL1}</span>
                                                {/* <span className="prefix">TRX</span> */}
                                            </li>
                                            <li className="bg-color1">
                                                <span className="title">Level 2 count</span>
                                                <span className="value userRefsLevel3">{this.state.referralRewardsL2}</span>
                                                {/* <span className="prefix">TRX</span> */}
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12  wow fadeInUp">
                                        <div className="modal_valuemain">
                                            <div className="modal_valuemain_centerB">
                                                <div className="modal_valuetitle">Available to Claim</div>
                                                <div className="modal_valueresult">{this.state.rewardsAvailable != '-' ? this.fixingNumber(Number(this.state.rewardsAvailable)) : '-'} </div>
                                                <div className="modal_valuetitle dotted_box font_doller" style={{ color: this.state.rewardsAvailable != '-' ? '' : 'transparent' }}>{this.state.rewardsAvailable != '-' ? "( $ " + this.getNRGYTo$(this.fixingNumber(Number(this.state.rewardsAvailable))) + " )" : '-'}</div>
                                                <input type="text" className="input-padding" style={{ color: '#000' }} placeholder="Enter Rewards Amount" disabled={this.state.stakeStatus == "inprogress"} value={this.state.rewardsAvailInput} onChange={(event) => this.claimAmountChanged(event)} />
                                                <br />

                                                {(this.state.stakeStatus == "start") ? (<button className="button-padding margin-15" style={{ width: '135px', marginRight: '5px' }} value="Stake" disabled={this.state.rewardsAvailable == '-'} onClick={() => this.restakBLDRClaimRewards()}>Stake</button>) : ''}
                                                {(this.state.stakeStatus == "inprogress") ? (<button className="button-padding margin-15 inprogress" style={{ width: '135px', marginRight: '5px' }} value="Stake" disabled={true}>In Progress ... </button>) : ''}

                                                {(this.state.stakeStatus == "start") ? (<button className="button-padding margin-15" style={{ width: '135px', marginRight: '5px' }} value="Claim in USDC" disabled={this.state.rewardsAvailable == '-'} onClick={() => this.claimBuilderRewards()} >Claim in USDC</button>) : ''}
                                                {(this.state.stakeStatus == "inprogress") ? (<button className="button-padding margin-15 inprogress" style={{ width: '135px', marginRight: '5px' }} value="Claim in USDC" disabled={true}>In Progress ... </button>) : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-12 border-box">
                                <div className="container" id="counter2" style={{ textAlign: 'center' }}>
                                    <h2 id="headline">Countdown to Claim Your Compounded Yield</h2>
                                    <div id="countdown">
                                        <ul id="countdown-ul">
                                            <li><span id="days2"></span>days</li>
                                            <li><span id="hours2"></span>Hours</li>
                                            <li><span id="minutes2"></span>Minutes</li>
                                            <li><span id="seconds2"></span>Seconds</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className=" margin-15" style={{ padding: '10px' }}><b>Claimable BLDR Rewards 1 :</b>&nbsp;{this.state.rewardsClaimable7}</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div style={{ float: 'right' }}>
                                            <button className="button-padding margin-15" value="Claim" disabled={this.isClaimDisabled()} onClick={() => this.openClaimCall7()} >Claim 1</button>
                                            &nbsp;&nbsp;<i className="fa fa-info-circle" data-tip="Compounded yield(based on your earned rewards balance) will be given to the users who are inside the program before 8th week and have not unstaked till date. Next month yield drop will be for week 12th to 15th."></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className=" margin-15" style={{ padding: '10px' }}><b>Claimable BLDR Rewards 2 :</b>&nbsp;{this.state.rewardsClaimable11}</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div style={{ float: 'right' }}>
                                            <button className="button-padding margin-15" value="Claim" disabled={this.isClaimDisabled()} onClick={() => this.openClaimCall()} >Claim 2</button>
                                            &nbsp;&nbsp;<i className="fa fa-info-circle" data-tip="Compounded yield(based on your earned rewards balance) will be given to the users who are inside the program before 12th week and have not unstaked till date. Next month yield drop will be for week 8th to 11th."></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                                {/* <div className="row">
                        <div className="col-md-12  wow fadeInUp">
                            <h3>BLDR in reservation queue</h3>

                            {this.getTableContent()}
                        </div>
                    </div> */}
                            </div>
                        </div>
                    </div>
                    <ReactTooltip></ReactTooltip>
                    {(this.isAnyTransactionInProgress()) ? (<div id="toast-container" className="toast-top-right">
                        <div className="toast toast-info" aria-live="assertive">
                            <div className="toast-message">Transaction is in progress.</div>
                        </div>
                    </div>) : ''}

                    <Dialog onClose={() => this.closeOpenUnstakeModal()} aria-labelledby="customized-dialog-title" open={this.state.isUnstakeOpen}>
                        <DialogTitle id="customized-dialog-title" style={{ fontSize: '18px' }} onClose={() => this.closeOpenUnstakeModal()}>
                            <span style={{ fontSize: '15px', fontWeight: '500' }}>You may need to approve more than one transaction to claim</span>
                        </DialogTitle>
                        <DialogContent dividers>
                            <div>
                                You understand that you will incur 15% fees on any amount you claim. You will give up the portion of this weeks earned rewards that are allocated to you.
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <button className="maindescbut investButton1" style={{ width: '120px', fontSize: '13px', margin: '0px', display: 'initial' }} onClick={() => this.claimRewardClick()}>I Confirm</button>
                        </DialogActions>
                    </Dialog>

                </div >
                <Modal
                    visible={this.state.popupVisible}
                    width="400"
                    height="200"
                    backgroundColor='blue'
                    effect="fadeInDown"
                    onClickAway={() => this.closeWalletPopup()}
                >
                    <div className="walletbox">
                        <div className="titlebar">
                            <div className="title">Connect Wallet</div>
                            <div className="iconbtn" onClick={() => { this.closeWalletPopup() }}><i className="fas fa-times-circle"></i></div>
                            <div style={{ clear: 'both' }}></div>
                        </div>

                        <div className="inner">
                            <ul className="walletnames">
                                <li onClick={() => { this.connectToMetaMaskNetwork() }}>
                                    <span className="walleticon"><img src="media/img/metamask.svg" width="50px" /></span>
                                    <br />
                                    <div className="walletname">MetaMask</div>
                                    <br />
                                    <div className="walletDesc">Connect to your MetaMask Wallet</div>
                                </li>
                                <li onClick={() => { this.connectToConnectWallet() }}>
                                    <span className="walleticon"><img src="media/img/wallet-connect.svg" width="50px" /></span>
                                    <br />
                                    <div className="walletname">WalletConnect</div>
                                    <br />
                                    <div className="walletDesc">Scan with WalletConnect to connect</div>
                                </li>
                                <li onClick={() => { this.connectToMetaMaskNetwork() }}>
                                    <span className="walleticon"><img src="media/img/TWT.svg" width="50px" /></span>
                                    <br />
                                    <div className="walletname">Trust Wallet</div>
                                    <div className="walletDesc">Connect to your Trust Wallet</div>
                                </li>
                            </ul>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}
